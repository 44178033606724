@import "../mixins";
.personal-card {
  border-radius: 10px;
  overflow: hidden;
  width: 29.6%;
  box-shadow: 0px 0px 5px 1px #00000040;

  .info {
    padding: 15px 12px;
    background-color: #fff;

    .title {
      font-size: 14px;
      font-weight: 700;
      line-height: 16px;
      color: #001d48;
      margin-bottom: 3px;
    }
    .job {
      display: block;
      font-size: 10px;
      font-weight: 400;
      line-height: 14px;
      color: #001d48;
    }
    .title,
    .job {
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 1;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  .spiker-img {
    width: 100%;
    aspect-ratio: 1/1;
    overflow: hidden;
    .placeholder{
      display: inline-block;
      width: 100%;
      height: 100%;
      @include loadingStye()
    }
    img {
      width: 100%;
      &.not-found {
        object-fit: cover;
        height: 100%;
      }
    }
  }
}
@media (max-width: 992px) {
  .personal-card {
    width: 30%;
  }
}

@media (max-width: 575px) {
  .personal-card {
    width: 47%;
    .info {
      padding: 6px 10px;
      .title {
        margin-bottom: 2px;
        font-size: 12px;
        line-height: 14px;
      }
      .job {
        line-height: 12px;
      }
    }
  }
}

@media (max-width: 350px) {
  .personal-card {
    width: 100%;
  }
}
