.detail-banner {
  width: 100%;
  padding: 30px 0;
  background-color: #8aaad9;
  position: relative;
  .container {
    .row {
      justify-content: space-between;
      align-items: center;
      &.finish {
        justify-content: center;
      }
      .banner-info {
        width: 49%;
        .status-alert {
          text-align: center;
          border-radius: 7px;
          background: #0046ac;
          padding: 10px 18px;
          margin-bottom: 20px;
          font-size: 16px;
          font-weight: 600;
          line-height: 22px;
          color: #ffffff;
        }
        .date {
          width: 100%;
          display: flex;
          justify-content: space-between;
          margin-bottom: 38px;
          .date-box {
            width: 28.1%;
            aspect-ratio: 1/1;
            border-radius: 100%;
            outline: none;
            border: 2px solid #ffffff;
            .date-info {
              width: 100%;
              height: 100%;
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: center;
              gap: 3px;
              .time {
                font-size: 24px;
                font-weight: 700;
                line-height: 32px;
                color: #fff;
              }
              .text {
                font-size: 14px;
                font-weight: 400;
                line-height: 18px;
                color: #fff;
              }
            }
          }
          .day {
            border-radius: 100% 100% 100% 0%;
          }
          .minute {
            border-radius: 0% 100% 100% 100%;
          }
        }
        .title {
          font-size: 24px;
          font-weight: 700;
          line-height: 29px;
          color: #fff;
          margin-bottom: 38px;
        }
        .btn {
          cursor: pointer;
          border: none;
          outline: none;
          padding: 14px;
          max-width: 197px;
          width: 100%;
          border-radius: 25px;
          font-size: 14px;
          font-weight: 500;
          line-height: 21px;
          background-color: #0046ac;
          color: #fff;
          &.complete {
            background-color: #fff;
            color: #0046ac;
            border: 1px solid #0046ac;
          }
        }
      }
      .banner-img {
        width: 40%;
        aspect-ratio: 1/1;
        img {
          width: 100%;
          border-radius: 7px;
        }
      }
    }
  }
  .finish-mode-name {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    text-align: center;
    padding: 40px;
    background: #00000033;
    font-size: 24px;
    font-weight: 700;
    line-height: 28.8px;
    color: #ffffff;
    filter: blur();
  }
}

@media (max-width: 992px) {
  .detail-banner {
    padding: 20px 0;
    .container {
      .row {
        .banner-info {
          width: 50.8%;
          .status-alert {
            font-size: 14px;
          }
          .date {
            width: 100%;
            margin-bottom: 30px;
            .date-box {
              width: 28.9%;
              .date-info {
                gap: 2px;
                .time {
                  font-size: 22px;
                  line-height: 28px;
                }
                .text {
                  font-size: 12px;
                  line-height: 12px;
                }
              }
            }
          }
          .title {
            margin-bottom: 30px;
            font-size: 20px;
            line-height: 24px;
          }
          .btn {
            padding: 11px;
            max-width: 216px;
            border-radius: 20px;
            font-size: 12px;
            line-height: 17px;
          }
        }
        .banner-img {
          width: 37.1%;
          img {
            width: 100%;
            aspect-ratio: 1/1;
            border-radius: 7px;
          }
        }
      }
    }
    .finish-mode-name {
      padding: 20px;
      font-size: 20px;
      line-height: 24px;
    }
  }
}
@media (max-width: 750px) {
  .detail-banner {
    .container {
      .row {
        .banner-info {
          width: 50.8%;
          .date {
            margin-bottom: 25px;
          }
          .title {
            margin-bottom: 25px;
            font-size: 18px;
          }
          .btn {
            padding: 11px;
            max-width: 216px;
            border-radius: 20px;
            font-size: 12px;
            line-height: 17px;
          }
        }
        .banner-img {
          width: 40.1%;
        }
      }
    }
    .finish-mode-name {
      padding: 10px;
      font-size: 18px;
    }
  }
}
@media (max-width: 575px) {
  .detail-banner {
    .container {
      .row {
        flex-direction: column;
        gap: 15px;
        .banner-info {
          width: 100%;
          .date {
            width: 100%;
            margin-bottom: 20px;
            .date-box {
              width: 28.9%;
            }
          }
          .title {
            margin-bottom: 20px;
            font-size: 20px;
          }
        }
        .banner-img {
          width: 80%;
        }
      }
    }
    .finish-mode-name {
      padding: 10px;
      font-size: 14px;
    }
  }
}
@media (max-width: 350px) {
  .detail-banner {
    .container {
      .row {
        .banner-info {
          .date {
            .date-box {
              width: 29.9%;
            }
          }
          .title {
            font-size: 16px;
          }
          .btn {
            max-width: 100%;
          }
        }
        .banner-img {
          width: 100%;
        }
      }
    }
  }
}
@media print {
  .detail-banner {
    display: none;
  }
}
