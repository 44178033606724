.payment-history {
  width: 100%;
  border-radius: 5px;
  background-color: #fff;
  padding: 20px;
  .balance-area {
    margin-bottom: 20px;
    border-radius: 15px;
    padding: 30px 10px 10px 30px;
    background: linear-gradient(0deg, #0046ac, #0046ac),
      linear-gradient(
        93.69deg,
        rgba(255, 255, 255, 0) 0%,
        rgba(255, 255, 255, 0.15) 45.83%,
        rgba(255, 255, 255, 0) 100%
      );
    position: relative;
    height: 184px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .balance-information {
      .caption {
        font-size: 14px;
        font-weight: 400;
        line-height: 21px;
        color: #ffffff;
      }
      .wallet {
        font-size: 28px;
        font-weight: 400;
        line-height: 42px;
        color: #ffffff;
      }
    }
    .incriment-balance-btn {
      align-self: flex-end;
      width: max-content;
      padding: 10px 15px;
      border: none;
      outline: none;
      cursor: pointer;
      border-radius: 11px;
      background-color: #fff;
      display: flex;
      gap: 8px;
      align-items: center;
      z-index: 10;
      img {
        width: 24px;
        height: 24px;
        object-fit: cover;
      }
    }
    .pattern-top-right {
      position: absolute;
      top: 0;
      right: 0;
    }
    .pattern-bottom-left {
      position: absolute;
      bottom: -25px;
      left: 0;
      transform: rotate(180deg);
    }
  }
  .history {
    .history-tab-list {
      display: flex;
      gap: 10px;
      .history-tab-item {
        padding: 5px 10px;
        border-bottom: 2px solid transparent;
        cursor: pointer;
        transition: 0.3s ease;
        user-select: none;
        &.active {
          border-bottom-color: #0046ac;
        }
      }
    }
    .history-list {
      margin-top: 20px;
      min-height: 24vh;
      .history-group {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 10px;
        padding: 6px 10px;
        border-bottom: 1px solid #e6edf7;
        cursor: pointer;
        &:last-child {
          border-bottom: none;
        }
        .left-side {
          width: 100%;
          display: flex;
          gap: 20px;
          align-items: center;
          .text {
            font-size: 16px;
            font-weight: 500;
            line-height: 16.67px;
            color: #001d48;
          }
          .icon {
            img {
              width: 30px;
              height: 30px;
              object-fit: cover;
              border-radius: 5px;
            }
          }
        }
        .money {
          font-size: 14px;
          font-weight: 500;
          line-height: 16.67px;
          color: #001d48;
          text-wrap: nowrap;
          &.income {
            color: #00d68f;
          }
        }
        .info {
          display: flex;
          align-items: center;
          gap: 40px;
          .date {
            text-wrap: nowrap;
            font-size: 14px;
            font-weight: 500;
            line-height: 16.67px;
            color: #7f8ea3;
          }
          .alert-icon {
            width: 20px;
            height: 20px;
            img {
              width: 100%;
              height: 100%;
              object-fit: cover;
              border-radius: 100%;
            }
          }
        }
      }
    }
    .alert {
      width: 100%;
      height: 200px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 14px;
      font-weight: 500;
      line-height: 16.67px;
      color: #7f8ea3;
    }
  }
}

@media (max-width: 992px) {
  .payment-history {
    padding: 10px;
    .balance-area {
      margin-bottom: 10px;
      border-radius: 15px;
      padding: 10px;

      height: 170px;
      .balance-information {
        .caption {
          font-size: 12px;
          line-height: 18px;
        }
        .wallet {
          font-size: 22px;
          line-height: 33px;
        }
      }
      .incriment-balance-btn {
        padding: 8px 10px;
        border-radius: 10px;
        gap: 5px;
        font-size: 12px;
        line-height: 18px;

        img {
          width: 15px;
          height: 15px;
        }
      }
      .pattern-top-right {
        img {
          max-width: 300px;
          width: 100%;
          object-fit: contain;
        }
      }
      .pattern-bottom-left {
        bottom: 0px;
        img {
          max-width: 300px;
          width: 100%;
          object-fit: contain;
        }
      }
    }
    .history {
      .history-tab-list {
        display: flex;
        gap: 10px;
        .history-tab-item {
          padding: 5px 10px;
          border-bottom: 2px solid transparent;
          cursor: pointer;
          transition: 0.3s ease;
          user-select: none;
          font-size: 14px;
          &.active {
            border-bottom-color: #0046ac;
          }
        }
      }
      .history-list {
        margin-top: 20px;
        min-height: 24vh;
        .history-group {
          gap: 5px;
          padding: 6px 0px;

          .left-side {
            gap: 10px;
            .text {
              font-size: 12px;
              line-height: 16.67px;
            }
            .icon {
              img {
                width: 25px;
                height: 25px;
              }
            }
          }
          .money {
            font-size: 12px;
            line-height: 16.67px;
          }
          .info {
            gap: 10px;
            .date {
              font-size: 12px;
              line-height: 16.67px;
            }
            .alert-icon {
              width: 16px;
              height: 16px;
            }
          }
        }
      }
    }
  }
}
