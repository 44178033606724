.home-banner {
  width: 100%;
  background-color: #5483c7;
  .container {
    .row {
      justify-content: space-between;
      align-items: center;
      .banner-info {
        width: 42.3%;
        .banner-caption {
          font-size: 30px;
          font-weight: 600;
          line-height: 36px;
          color: #fff;
          margin-bottom: 7px;
        }
        .banner-text {
          width: 100%;
          font-size: 16px;
          font-weight: 500;
          line-height: 19px;
          color: #fff;
        }
      }
      .banner-images {
        display: flex;
        justify-content: space-between;
        gap: 22px;
        // width: 44.2%;
        width: 442px;
        .baner-img-box {
          width: 45%;
          max-width: 210px;
          aspect-ratio: 1/1;
          img {
            width: 100%;
          }
        }
      }
    }
  }
}
@media (max-width: 992px) {
  .home-banner {
    width: 100%;
    background-color: #5483c7;
    .container {
      .row {
        justify-content: space-between;
        align-items: center;
        .banner-info {
          width: 51.2%;
          .banner-caption {
            font-size: 18px;
            line-height: 22px;
          }
          .banner-text {
            font-size: 14px;
            line-height: 17px;
          }
        }
        .banner-images {
          display: flex;
          justify-content: space-between;
          gap: 22px;
          // width: 42.3%;
          // width: 278px;
          .baner-img-box {
            width: 45%;
            max-width: 210px;
            aspect-ratio: 1/1;
            img {
              width: 100%;
            }
          }
        }
      }
    }
  }
}
