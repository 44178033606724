@import "../mixins";
.contact {
  .container {
    .caption {
      @include caption();
    }
    .map {
      width: 100%;
      height: 292px;
      margin-bottom: 20px;
    }
  }
  .contact-info {
    width: 100%;
  min-height: 48vh;

    background-color: #fff;
    padding: 20px 0;
    .container {
      .contact-area {
        width: 100%;
        box-shadow: 0px 0px 10px 0px #001d4845;
        border-radius: 57px;
        padding: 8px;
        display: flex;
        justify-content: space-between;
        gap: 5px;
        .contact-card {
          display: flex;
          align-items: center;
          width: 100%;
          gap: 15px;
          &:first-child,
          &:last-child {
            max-width: 320px;
          }
          &:nth-child(2) {
            max-width: 260px;
          }
          &:last-child {
            .information {
              max-width: 195px;
              width: 100%;
            }
          }
          .icon {
            max-width: 100px;
            width: 100%;

            border-radius: 100%;
            aspect-ratio: 1/1;
            overflow: hidden;
            img {
              width: 100%;
              height: 100%;
              object-fit: cover;
            }
          }
          .information {
            .link-list {
              .link-item {
                font-size: 12px;
                font-weight: 400;
                line-height: 18px;
                color: #001d48;
                a {
                  color: inherit;
                  word-break: break-all;
                  text-decoration: underline;
                }
              }
            }
          }
        }
      }
    }
  }
}
@media (max-width: 992px) {
  .contact {
    .container {
      .caption {
        padding: 16px 0;
        font-size: 18px;
        line-height: 18px;
      }
      .map {
        margin-bottom: 15px;
      }
    }
    .contact-info {
      background-color: #fff;
      min-height: 100%;

      padding: 15px 0;
      .container {
        .contact-area {
          width: 100%;
          box-shadow: 0px 0px 10px 0px #001d4845;
          box-shadow: none;
          border-radius: 0;
          padding: 0px;
          display: flex;
          justify-content: center;
          flex-wrap: wrap;
          gap: 4%;
          row-gap: 20px;
          .contact-card {
            display: flex;
            align-items: center;
            box-shadow: 0px 0px 10px 0px #001d4845;
            max-width: none !important;
            border-radius: 57px;
            padding: 8px;
            width: 48%;
          }
          .information {
            padding: 5px 15px 5px 0;
          }
        }
      }
    }
  }
}
@media (max-width: 575px) {
  .contact {
    .container {
      .caption {
        padding: 12px 0;
        font-size: 16px;
        line-height: 20px;
      }
    }
    .contact-info {
      padding: 20px 0;
      .container {
        .contact-area {
          width: 100%;
          box-shadow: 0px 0px 10px 0px #001d4845;
          padding: 8px 10px;
          display: flex;
          justify-content: flex-start;
          gap: 17px;
          border-radius: 57px;
          .contact-card {
            padding: 0;
            box-shadow: none;
            width: 100%;
  
          }
        }
      }
    }
  }
}
