@import "../../mixins";
.course-conferance-spiker {
  @include specialCaption();
}
.course-conferance-spiker {
  .container {
    .spikers {
      width: 82.6%;
      margin: 0 auto;
      display: flex;
      flex-wrap: wrap;
      row-gap: 15px;
      // justify-content: space-between;
      gap: 5%;
      row-gap: 20px;

    }
    .btn-area {
      text-align: center;
      button {
        border: none;
        outline: none;
        cursor: pointer;
        display: inline-block;
        margin: 15px 0;
        box-shadow: 0px 5px 15px -2px #0046ac47;
        background-color: #0046ac;
        color: #fff;
        padding: 10px;
        font-size: 14px;
        font-weight: 500;
        line-height: 17px;
        border-radius: 19px;
        max-width: 118px;
        width: 100%;
      }
    }
  }
}
@media (max-width: 992px) {
  .course-conferance-spiker {
    .container {
      .spikers {
        width: 100%;
      }
    }
  }
}

@media (max-width: 575px) {
  .course-conferance-spiker {
    .container {
      .spikers {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
      }
    }
  }
}
@media print {
  .course-conferance-spiker {
    display: none;
  }
}
