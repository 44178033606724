/* ------------------------------- Repeat Code ------------------------------ */
.last-add,
.innovation {
  &:hover {
    .container {
      .innovation-slider {
        .swiper {
          .swiper-button-next,
          .swiper-button-prev {
            &::after {
              opacity: 1;
            }
          }
        }
      }
    }
  }
  .container {
    .innovation-title,
    .last-add-title {
      font-size: 26px;
      font-weight: 600;
      line-height: 26px;
      color: #0046ac;
      padding: 11px 0;
      margin-bottom: 15px;
      display: flex;
      align-items: center;
      gap: 20px;
      .icon {
        img {
          display: flex;
          width: 52px;
          height: 26px;
          object-fit: cover;
        }
      }
    }
  }
}
/* ----------------------------- Last ADD Slider ---------------------------- */
.last-add {
  .container {
    .last-add-title {
      margin-bottom: 0px;
      padding-bottom: 0;
    }
    .swiper {
      .swiper-wrapper {
        height: 410px;
        .swiper-slide {
          min-height: 409px;
          height: 100%;
          padding-top: 40px;
          margin-right: 20px;
          .general-card {
            display: inline-block;
            width: 100%;
            overflow: hidden;
            transform: scale(0.95);
            transition: 0.5s ease;
            .middle {
              height: 135px;
              img {
                object-fit: cover;
              }
            }
          }
        }
        .swiper-slide-prev {
          transform: translateX(-10px);
        }
        .swiper-slide-next {
          transform: translateX(10px);
        }

        .swiper-slide-active {
          margin-right: 0;
          .general-card {
            transform: scale(1.15);
            .bottom {
              border-bottom-left-radius: 15px;
              border-bottom-right-radius: 15px;
            }
          }
        }
      }
      .swiper-button-next,
      .swiper-button-prev {
        &::after {
          font-size: 18px;
          padding: 10px 14px;
          color: #fff;
          border-radius: 100%;
          background-color: rgba(0, 0, 0, 0.3);
          transition: 0.33s ease;
          opacity: 0;
        }
      }
    }
  }
}

/* ---------------------------- Innovation Slider --------------------------- */
.innovation {
  &:hover {
    .container {
      .innovation-slider {
        .swiper {
          .swiper-button-next,
          .swiper-button-prev {
            &::after {
              opacity: 1;
            }
          }
        }
      }
    }
  }
  .container {
    .innovation-slider {
      margin-bottom: 15px;
      .swiper {
        .swiper-wrapper {
          .swiper-slide {
            height: auto;
            .inovation-card {
              width: 100%;
              height: auto;
              &:hover {
                .bottom {
                  .info {
                    opacity: 1;
                    visibility: visible;
                    transform: translateX(0);
                  }
                }
              }
              .top {
                width: 100%;
                height: 130px;
                img {
                  width: 100%;
                  height: 100%;
                  object-fit: cover;
                }
              }
              .bottom {
                margin-top: 9px;
                .date {
                  font-size: 14px;
                  font-weight: 600;
                  line-height: 16px;
                  color: #0046ac;
                  display: block;
                  margin-bottom: 9px;
                }
                .title {
                  font-size: 14px;
                  font-weight: 500;
                  line-height: 16px;
                  color: #000000;
                  margin-bottom: 9px;
                  max-height: 32px;
                  height: 30px;
                  display: inline-block;
                  display: -webkit-box;
                  -webkit-box-orient: vertical;
                  -webkit-line-clamp: 2;
                  overflow: hidden;
                  text-overflow: ellipsis;
                }
                .text {
                  font-size: 12px;
                  font-weight: 400;
                  line-height: 16px;
                  color: #000000;
                  display: -webkit-box;
                  -webkit-box-orient: vertical;
                  -webkit-line-clamp: 3;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  ul {
                    display: none;
                  }
                }
                .info {
                  display: flex;
                  align-items: center;
                  justify-content: flex-end;
                  gap: 10px;
                  font-size: 14px;
                  font-weight: 300;
                  line-height: 16px;
                  color: #0046ac;
                  opacity: 0;
                  visibility: hidden;
                  transition: 0.5s ease;
                  transform: translateX(-50%);
                  img {
                    display: flex;
                    align-items: center;
                    width: 32px;
                    height: 16px;
                    object-fit: cover;
                  }
                }
              }
            }
          }
        }
        .swiper-button-next,
        .swiper-button-prev {
          &::after {
            font-size: 18px;
            padding: 10px 14px;
            color: #fff;
            border-radius: 100%;
            background-color: rgba(0, 0, 0, 0.3);
            transition: 0.33s ease;
            opacity: 0;
          }
        }
      }
    }
  }
}
@media (max-width: 1024px) {
  .last-add,
  .innovation {
    .container {
      .innovation-slider {
        .swiper {
          .swiper-button-next,
          .swiper-button-prev {
            &::after {
              opacity: 1;
            }
          }
        }
      }
    }
  }
}
