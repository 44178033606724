.footer {
  width: 100%;
  background-color: #fff;
  padding: 23px 0;
  font-weight: 500;
  .container {
    .row {
      justify-content: space-between;
      gap: 10px;
      .left-side {
        .logo {
          max-width: 132px;
          width: 100%;
          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
        .contact-list {
          margin: 36px 0;
          .contact-item {
            font-size: 14px;
            font-weight: 500;
            line-height: 14px;
            color: #0046ac;
            padding: 2.5px 0;
            a {
              color: #00275f;
            }
          }
        }
      }
      .right-side {
        max-width: 500px;
        width: 100%;
        .subcribe-partner-info-area {
          .subtext {
            font-size: 16px;
            font-weight: 500;
            line-height: 19.2px;
            color: #001d48;
            margin-bottom: 15px;
          }
          .btn {
            display: inline-block;
            width: 100%;
            font-size: 14px;
            font-weight: 500;
            line-height: 18px;
            text-align: left;
            color: #ffffff;
            padding: 13px;
            text-align: center;
            background: #0046ac;
            border-radius: 10px;
          }
        }
        .menu-list {
          align-self: flex-end;
          margin-bottom: 36px;
          margin-top: 10px;
          display: flex;
          justify-content: flex-end;
          flex-wrap: wrap;
          gap: 20px;
          .menu-item {
            a {
              font-size: 14px;
              font-weight: 500;
              line-height: 12px;
              color: #001d48;
              padding: 5px 10px 5px 0;
              text-wrap: nowrap;
            }
          }
        }
      }
    }
    .last {
      border-top: 1px solid #0f6c8e;
      .row {
        justify-content: space-between;
        font-size: 14px;
        font-weight: 500;
        color: #001d48;
        padding-top: 24px;
        .last-text {
          p {
            color: inherit;
            font-size: 14px;
            font-weight: 500;
            line-height: 16px;
          }
        }
        .language {
          padding-left: 10px;
          cursor: pointer;
          height: 100%;
          display: flex;
          align-items: center;
          gap: 7px;
          .icon {
            display: flex;
            align-self: center;
          }
          .lang-icon {
            svg {
              width: 18px;
            }
          }
          .curent-lang {
            line-height: 14px;
            color: inherit;
            position: relative;
            .lang-list {
              position: absolute;
              bottom: 25px;
              left: -25px;
              width: 130px;
              border: 0.5px solid #0046ac;
              background-color: #fff;
              border-radius: 4px;
              overflow: hidden;
              visibility: hidden;
              opacity: 0;
              height: 0;
              transform: translateY(10px);
              transition: 0.5s ease;
              &.active {
                opacity: 1;
                visibility: visible;
                transform: translateY(0);
                // height: 83px;
                height: 55px;
              }
              .lang-item {
                width: 100%;
                padding: 4px 6px;
                font-size: 14px;
                font-weight: 500;
                line-height: 18px;
                color: inherit;
                background-color: transparent;
                transition: 0.5s ease;
                &:hover {
                  background-color: #ededed;
                }
              }
            }
          }
          .drop-icon {
            svg {
              width: 18px;
            }
            transform: rotate(0deg);
            transition: 0.5s ease;
            &.active-icon {
              transform: rotate(180deg);
            }
          }
        }
      }
    }
  }
}
@media (max-width: 992px) {
  .footer {
    width: 100%;
    background-color: #fff;
    padding: 23px 0;
    .container {
      .row {
        justify-content: space-between;
        .left-side {
          .logo {
            max-width: 132px;
            width: 100%;
            img {
              width: 100%;
              height: 100%;
              object-fit: cover;
            }
          }
          .contact-list {
            margin: 36px 0;
            .contact-item {
              font-size: 14px;
              font-weight: 500;
              line-height: 14px;
              color: #0046ac;
              padding: 2.5px 0;
              a {
                color: #00275f;
              }
            }
          }
        }
        .right-side {
          .subcribe-partner-info-area {
            .subtext {
              font-size: 14px;
            }
          }
          .menu-list {
            gap: 15px;
            .menu-item {
              a {
                font-size: 14px;
                font-weight: 500;
                line-height: 12px;
                color: #001d48;
                padding: 5px 10px 5px 0;
              }
            }
          }
        }
      }
      .last {
        border-top: 1px solid #0f6c8e;
        .row {
          justify-content: space-between;
          font-size: 14px;
          font-weight: 500;
          color: #001d48;
          padding-top: 24px;
          .language {
            padding-left: 10px;
            cursor: pointer;
            height: 100%;
            display: flex;
            align-items: center;
            gap: 7px;
            .icon {
              display: flex;
              align-self: center;
            }
            .lang-icon {
              svg {
                width: 18px;
              }
            }
            .curent-lang {
              line-height: 14px;
              color: inherit;
            }
          }
        }
      }
    }
  }
}
@media (max-width: 575px) {
  .footer {
    width: 100%;
    background-color: #fff;
    padding: 23px 0;
    .container {
      .row {
        justify-content: space-between;
        flex-direction: column;
        .left-side {
          .logo {
            max-width: 132px;
            width: 100%;
            img {
              width: 100%;
              height: 100%;
              object-fit: cover;
            }
          }
          .contact-list {
            margin: 20px 0;
            .contact-item {
              font-size: 14px;
              font-weight: 500;
              line-height: 14px;
              color: #0046ac;
              padding: 2.5px 0;
              a {
                color: #00275f;
              }
            }
          }
        }
        .right-side {
          .menu-list {
            margin-top: 10px;
            align-self: flex-start;
            flex-wrap: wrap;
            justify-content: flex-start;
            align-items: flex-start;
            gap: 10px;
            margin-bottom: 20px;
            text-align: left;
            .menu-item {
              a {
                font-size: 14px;
                font-weight: 500;
                line-height: 12px;
                color: #001d48;
                padding: 5px 10px 5px 0;
              }
            }
          }
        }
      }
      .last {
        border-top: 1px solid #0f6c8e;
        .row {
          row-gap: 20px;
          padding-top: 20px;

          .language {
            padding-left: 0;
          }
        }
      }
    }
  }
}
@media print {
  .footer {
    display: none;
  }
}
