@import "../mixins";
.detail-card-area {
  width: 100%;
  .caption {
    @include caption();
  }
  .card-area {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    gap: 3.7%;
    row-gap: 30px;
    margin-bottom: 15px;
    .general-card {
      width: 22.2%;
      min-height: min-content;
      .bottom {
        height: auto;
      }
    }
  }
  .btn-area {
    text-align: center;
    .btn {
      @include btn(0 0 15px 0, 118px, 10px);
    }
  }
}
@media (max-width: 992px) {
  .detail-card-area {
    width: 100%;
    .caption {
      padding: 16px 0;
      font-size: 18px;
      line-height: 18px;
    }
    .card-area {
      gap: 2.7%;
      row-gap: 15px;
      margin-bottom: 15px;
      .general-card {
        width: 31.4%;
      }
    }
  }
}
@media (max-width: 750px) {
  .detail-card-area {
    width: 100%;
    .caption {
      padding: 16px 0;
      font-size: 18px;
      line-height: 18px;
    }
    .card-area {
      gap: 5.7%;
      row-gap: 15px;
      .general-card {
        width: 47%;
      }
    }
  }
}

@media (max-width: 575px) {
  .detail-card-area {
    width: 100%;
    .caption {
      padding: 12px 0;
      font-size: 16px;
      line-height: 20px;
    }
  }
}
@media (max-width: 310px) {
    .detail-card-area {
        width: 100%;
        .caption {
          padding: 16px 0;
          font-size: 18px;
          line-height: 18px;
        }
        .card-area {
          gap: 5.7%;
          row-gap: 15px;
          .general-card {
          width: 100%;
          }
        }
      }
}
