.detail-diarly {
  width: 100%;
  margin-bottom: 10px;
  .container {
    .caption {
      width: 100%;
      display: flex;
      justify-content: space-between;
      padding: 12px 0;
      .title {
        font-size: 20px;
        font-weight: 600;
        line-height: 20px;
        color: #0046ac;
        padding-right: 10px;
      }
      .download {
        font-size: 16px;
        font-weight: 400;
        line-height: 16px;
        color: #0046ac;
        text-decoration: underline;
      }
    }
    .date{
      font-size: 18px;
      font-weight: 600;
      line-height: 20px;
      color: #0046ac;
      padding-right: 10px;
      margin-bottom: 10px;
    }
    .program {
      width: 100%;
      background-color: #fff;
      border-radius: 15px;
      .line {
        width: 100%;
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 20px;
        padding: 12px 20px;
        transition: 0.5s ease;
        border-bottom: 0.5px solid #0046ac;
        &:last-child {
          border-bottom: none;
        }
        .program-title {
          width: 100%;
          // font-size: 16px;
          // font-weight: 600;
          // line-height: 22px;
          line-height: 10px;
          color: #001d48;
          page-break-inside: avoid;
          white-space: pre-wrap;
          white-space: -moz-pre-wrap;
          white-space: -pre-wrap;
          white-space: -o-pre-wrap;
          p,
          h1,
          h2,
          h3,
          h4,
          h5,
          h6,
          strong,
          li {
            line-height: 16px;
            font-size: 14px;
          }
        }
      }
      .date {
        display: flex;
        align-items: center;
        gap: 10px;
        .icon {
          display: flex;
          align-items: center;
          img {
            display: flex;
            align-items: center;
            width: 20px;
            height: 20px;
            object-fit: cover;
          }
        }
        .time {
          font-size: 14px;
          font-weight: 400;
          line-height: 24px;
          color: #001d48;
          text-wrap: nowrap;
        }
      }
    }
    .btn-area {
      text-align: center;
      button {
        border: none;
        outline: none;
        cursor: pointer;
        display: inline-block;
        margin: 15px 0;
        box-shadow: 0px 5px 15px -2px #0046ac47;
        background-color: #0046ac;
        color: #fff;
        padding: 10px;
        font-size: 14px;
        font-weight: 500;
        line-height: 17px;
        border-radius: 19px;
        max-width: 118px;
        width: 100%;
      }
    }
  }
}

@media (max-width: 992px) {
  .detail-diarly {
    width: 100%;
    .container {
      .caption {
        .title {
          font-size: 16px;
          line-height: 20px;
        }
        .download {
          font-size: 12px;
          line-height: 16px;
        }
      }
      .program {
        .line {
          padding: 11px 10px;
          .program-title {
            p,
            h1,
            h2,
            h3,
            h4,
            h5,
            h6,
            strong {
              font-size: 12px;
              line-height: 14px;
            }
          }
          .date {
            gap: 5px;
            text-align: right;
            .time {
              font-size: 12px;
              line-height: 12px;
            }
          }
        }
      }
    }
  }
}
@media print {
  .detail-diarly {
    display: none;
  }
}
