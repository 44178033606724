@import "../mixins";
.landing-page {
  .swiper {
    .swiper-wrapper {
      .swiper-slide {
        width: 100%;
        height: 92vh;
        .langing-area {
          position: relative;
          width: 100%;
          height: 100%;
          .bg-img {
            width: 100%;
            height: 100%;
            .placeholder {
              display: inline-block;
              width: 100%;
              height: 100%;
              @include loadingStye();
            }
            img {
              width: 100%;
              height: 100%;
              object-fit: cover;
              display: none;
              &.desktop-bg-img {
                display: block !important;
              }
              &.mobile-bg-img {
                display: none !important;
              }
            }
          }
        }
      }
    }
    .swiper-pagination {
      margin-bottom: 27px;
      z-index: 1;
      .swiper-pagination-bullet {
        width: 14px;
        height: 10px;
        background-color: #b5b5b5;
        border-radius: 4px;
        transition: 0.3s ease;
      }
      .swiper-pagination-bullet-active-main {
        width: 16px;
        height: 10px;
        background-color: #ffffff;
      }
    }
  }
}

/* ------------------------------- Responsive ------------------------------- */

@media (max-width: 600px) {
  .landing-page {
    .swiper {
      .swiper-wrapper {
        .swiper-slide {
          .langing-area {
            .bg-img {
              img {
                &.desktop-bg-img {
                  display: none !important;
                }
                &.mobile-bg-img {
                  display: block !important;
                  object-position: top;
                }
              }
            }
          }
        }
      }
      .swiper-pagination {
        margin-bottom: 15px;
      }
    }
  }
}
