.innovation-detail {
  width: 100%;
  padding: 20px 0;
  .container {
    .row {
      justify-content: space-between;
      .innovation-img {
        width: 49%;
        aspect-ratio: 1/1;
        border-radius: 15px;
        overflow: hidden;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
      .innovation-info {
        background-color: #fff;
        border-radius: 15px;
        padding: 30px;
        width: 49%;
        .title {
          font-size: 24px;
          font-weight: 700;
          line-height: 29px;
          color: #00275f;
          padding-bottom: 10px;
          margin-bottom: 15px;
          border-bottom: 2px solid #001d48;
        }
        .personal-name {
          display: block;
          font-size: 17px;
          font-weight: 500;
          line-height: 17px;
          outline-color: #001d48;
          margin-bottom: 15px;
        }
        .date {
          font-size: 15px;
          font-weight: 400;
          line-height: 15px;
          color: #001d48;
        }
      }
    }
    .innovation-text {
      margin-top: 20px;
      background-color: #fff;
      border-radius: 15px;
      padding: 30px;
      width: 100%;
      .text {
        width: 100%;
        page-break-inside: avoid !important;
        white-space: pre-wrap !important;

        font-size: 16px;
        font-weight: 500;
        line-height: 8px;

        color: #001d48;
        p,
        h1,
        h2,
        h,
        h4,
        h5,
        h6 {
          padding: 0;
          margin: 0;
          line-height: 20px;
        }
        a {
          word-break: break-all;
            color:#1a00d6;
        }
        ol {
          padding-left: 18px;
          list-style-type: decimal !important;

          li {
            list-style-type: decimal !important;
            padding-left: 10px;
          }
        }
        ul {
          margin-block-start: 0 !important;
          margin-block-end: 0 !important;
          margin-inline-start: 0px;
          margin-inline-end: 0px;
          padding-inline-start: 0px !important;
          li {
            list-style: inside;
            line-height: 20px;
    
          }
        }
      }
    }
  }
}
/* ------------------------------- Responsive ------------------------------- */
@media (max-width: 992px) {
  .innovation-detail {
    padding: 15px 0;
    .container {
      .row {
 
        .innovation-info {
          padding: 20px;
          .title {
            margin-bottom: 8px;
            font-size: 16px;
            line-height: 19px;
          }
          .personal-name {
            margin-bottom: 6px;
            font-size: 14px;
            line-height: 14px;
          }
          .date {
            font-size: 12px;
            line-height: 12px;
          }
        }
      }
      .innovation-text {
        margin-top: 15px;
        padding: 20px;
        p {
          font-size: 14px;
          line-height: 16px;
        }
      }
    }
  }
}
@media (max-width: 575px) {
  .innovation-detail {
    padding: 15px 0;
    .container {
      .row {
        flex-direction: column;
        align-items: center;
        gap: 15px;
        .innovation-img {
          width: 100%;
        }
        .innovation-info {
          width: 100%;
        }
      }
    }
  }
}
