.calendar-area {
  width: 100%;
  padding: 10px 0;
  .container {
    .row {
      justify-content: space-between;
      .mini-calendar {
        width: 23.5%;
      }
      .filter {
        width: 100%;
        display: flex;
        gap: 10px;

        padding-bottom: 10px;
        margin: 10px 0;
        border-bottom: 1px solid #e6e6e6;
        .form-group {
          border: 1px solid #b5b5b5;
          border-radius: 4px;
          position: relative;
          height: 40px;
          width: 31.6%;
          background-color: #fff;
          &.select-group {
            width: 100%;
          }

          .label {
            font-size: 14px;
            line-height: 10px;
            font-weight: 400;
            letter-spacing: 0.5px;
            z-index: 1;
            position: absolute;
            color: #434343;
            transition: 0.5s ease;
            transform: translate(25px, 15px);
            &.animate {
              transform: translate(15px, -2px);
              background-color: #fff;
              font-size: 12px;
              line-height: 2px;
              color: #000;
              z-index: 4;
            }
          }
          .caption {
            font-size: 12px;
            font-weight: 400;
            line-height: 2px;
            letter-spacing: 0.5px;
            color: #000;
            z-index: 4;
            position: absolute;
            transform: translate(15px, -2px);
            background-color: #fff;
            color: #000;
          }
          input {
            padding: 11px 15px;
            width: 100%;
            height: 100%;
            background-color: transparent;
            outline: none;
            border: none;
            position: relative;
            z-index: 3;
            font-size: 14px;
            font-weight: 400;
            line-height: 21px;
            letter-spacing: 0.5px;
            color: #000;
            &[type="number"]::-webkit-inner-spin-button {
              -webkit-appearance: none;
            }
            &[type="date"] {
              cursor: pointer;
            }
            &[type="date"]::-webkit-calendar-picker-indicator {
              cursor: pointer;
              padding: 4px;
            }
            &:-webkit-autofill,
            &:-webkit-autofill:hover,
            &:-webkit-autofill:focus {
              -webkit-box-shadow: 0 0 0 1000px #ffffff inset;
              background-color: transparent !important;
            }
          }
          .select {
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            position: relative;
            cursor: pointer;
            input {
              cursor: pointer;
            }
            .icon {
              display: flex;
              margin-right: 14px;
            }
          }
          &:first-child {
            .select-text {
              &.active {
                height: 85px;
              }
            }
          }
          .select-text {
            position: absolute;
            top: 45px;
            left: 0;
            width: 100%;
            border: 1px solid #b5b5b5;
            background-color: #fff;
            border-radius: 5px;
            overflow: hidden;
            z-index: 5;
            transform: translateY(-10px);
            visibility: hidden;
            opacity: 0;
            height: 0;
            transition: 0.4s ease;
            &.active {
              overflow: visible;
              height: 200px;
              opacity: 1;
              visibility: visible;
              transform: translateY(0px);
            }
            .select-list {
              background-color: #fff;
              max-height: 200px;
              .select-item {
                cursor: pointer;
                font-size: 12px;
                line-height: 12px;
                letter-spacing: 0.5px;
                color: #434343;
                padding: 14px;
                transition: 0.3s ease;
                width: 100%;
                &:hover {
                  background: #e1e1e1;
                }
                &.active {
                  background: #e1e1e1;
                }
              }
            }

            // padding:14px ;
          }
          .error-message {
            font-size: 10px;
            font-weight: 600;
            line-height: 17px;

            color: #e83867;
            margin-bottom: 5px;
            padding-left: 3px;
          }
        }
      }

      .main-calendar {
        width: 74.9%;
        .navigation {
          position: relative;
          .nav-area {
            height: 38px;
            margin-bottom: 20px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 100%;
            height: 100%;
            .date-type {
              display: flex;
              gap: 10px;
              button {
                padding: 10px 20px;
                border-radius: 5px;
                font-size: 12px;
                font-weight: 600;
                line-height: 18px;
                width: 73px;
                text-align: center;
                outline: none;
                border: none;
                cursor: pointer;
                color: #001d48;
                transition: 0.5s ease;
                &.active {
                  background-color: #0046ac;
                  color: #fff;
                }
              }
            }
            .change-week-day {
              display: none;
              align-items: center;
              gap: 3px;
              &.active {
                display: flex;
              }
              .text {
                font-size: 12px;
                font-weight: 500;
                line-height: 18px;
                color: #001d48;
                margin-right: 15px;
                position: relative;
                &::after {
                  content: "";
                  position: absolute;
                  top: -6px;
                  right: -8px;
                  width: 1px;
                  height: 30px;
                  background-color: #000;
                }
              }
              button {
                font-size: 15px;
                text-align: center;
                width: 30px;
                height: 30px;
                background-color: #fff;
                box-shadow: 0px 0px 2px 0px rgba(0, 70, 172, 0.2196078431);
                border-radius: 5px;
                border-color: transparent;
                cursor: pointer;
              }
            }
          }
          .react-calendar {
            width: 100%;
            border: none;
            border-radius: 10px;
            .react-calendar__navigation {
              position: absolute;
              top: 4px;
              right: 0;
              display: flex;
              align-items: center;
              gap: 3px;
              .react-calendar__navigation__label {
                border: none;
                outline: none;
                font-size: 12px;
                font-weight: 500;
                line-height: 18px;
                letter-spacing: 0em;
                text-align: left;
                order: 1;
                margin-right: 15px;
                &:disabled{
                  color: #001d48;
                }
              }
              .react-calendar__navigation__prev-button {
                order: 2;
              }
              .react-calendar__navigation__next-button {
                order: 3;
              }
              .react-calendar__navigation__prev-button,
              .react-calendar__navigation__next-button {
                font-size: 15px;
                text-align: center;
                width: 30px;
                height: 30px;
                display: flex;
                align-items: center;
                justify-content: center;
                background-color: #fff;
                box-shadow: 0px 0px 2px 0px #0046ac38;
                border-radius: 5px;
                border-color: transparent;
                cursor: pointer;
              }
              .react-calendar__navigation__prev2-button,
              .react-calendar__navigation__next2-button {
                display: none;
              }
            }
            .react-calendar__viewContainer {
              .react-calendar__month-view {
                background-color: #fff;
                border-radius: 10px;
                overflow: hidden;
                .react-calendar__month-view__weekdays {
                  .react-calendar__month-view__weekdays__weekday {
                    text-align: center;
                    border-right: 0.5px solid #b0c6e5;
                    border-bottom: 0.5px solid #b0c6e5;
                    &:last-child {
                      border-right: 0.5px solid transparent;
                    }
                    abbr {
                      font-size: 9px;
                      font-weight: 500;
                      line-height: 13px;
                      color: #336bbd;
                    }
                  }
                }
                .react-calendar__month-view__days {
                  .react-calendar__tile {
                    border-color: transparent;
                    position: relative;
                    display: flex;
                    flex-direction: column;
                    gap: 2px;
                    padding: 0 5px;

                    border-right: 0.5px solid #b0c6e5;
                    border-bottom: 0.5px solid #b0c6e5;
                    width: 100%;
                    height: 107px;
                    background-color: #fff;
                    &:nth-child(7n) {
                      border-right: 0.5px solid transparent;
                    }
                    &:nth-child(n + 29) {
                      border-bottom: 0.5px solid transparent;
                    }
                    abbr {
                      font-size: 14px;
                      font-weight: 500;
                      line-height: 14px;
                      color: #001d48;
                      padding: 4px 8px;
                    }
                    .event-marker {
                      width: 100%;
                      overflow-wrap: anywhere;
                      padding: 2px;
                      border-radius: 3px;
                      font-weight: 400;
                      color: #fff;
                      height: 38px;
                      text-align: left;
                      .time {
                        font-size: 7px;
                        line-height: 7px;
                      }
                      .text {
                        font-size: 9px;
                        line-height: 10px;
                        display: -webkit-box;
                        -webkit-box-orient: vertical;
                        -webkit-line-clamp: 2;
                        overflow: hidden;
                        text-overflow: ellipsis;
                      }
                    }
                    .show-more {
                      position: absolute;
                      top: 2px;
                      right: 3px;
                      background: #8c8c8c;
                      font-size: 9px;
                      font-weight: 500;
                      line-height: 8px;
                      color: #fff;
                      border-radius: 6px;
                      padding: 4px;
                      height: min-content;
                      width: 60px;
                    }
                    &.react-calendar-update-week {
                      padding: 0 5px;
                      min-height: 450px !important;
                      height: 100%;
                      abbr {
                        position: absolute;
                        bottom: 0;
                        left: 0;
                        text-align: center;
                        background-color: #0046ac;

                        height: 20px;
                        width: 100%;
                        color: #fff !important;
                      }
                      .event-marker {
                        height: 65px;

                        .text {
                          -webkit-line-clamp: 4;
                        }
                      }
                      .show-more {
                        display: none;
                      }
                    }
                    &.react-calendar-update-day {
                      .event-marker {
                        position: relative;
                        &:after {
                          content: "";
                          position: absolute;
                          top: 0;
                          left: 0;
                          width: 100%;
                          height: 100%;
                          background-color: #fff;
                          opacity: 50%;
                          z-index: 2;
                        }
                      }
                    }
                  }

                  .week-visible {
                    display: none;
                  }
                  .react-calendar__month-view__days__day--neighboringMonth {
                    background-color: #e8e8e8;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
@media (max-width: 1024px) {
  .calendar-area {
    .container {
      .row {
        flex-direction: column-reverse;
        .mini-calendar {
          width: 100%;
        }

        .main-calendar {
          width: 100%;
        }
      }
    }
  }
}
@media (max-width: 768px) {
  .calendar-area {
    .container {
      .row {
        flex-direction: column-reverse;
        .main-calendar {
          width: 100%;
          .navigation {
            display: none;
          }
        }
      }
    }
  }
}
