@import "../mixins";
.ticket {
  position: fixed;
  top: 0;
  left: 0;
  background-color: #00000025;
  width: 100%;
  height: 100vh;
  z-index: 99;
  display: flex;
  justify-content: center;
  align-items: center;

  .ticket-body {
    padding: 30px 40px;
    position: relative;
    max-width: 826px;
    width: 100%;
    background-color: #fff;
    box-shadow: 0px 11px 55px 0px #3333331f;
    border-radius: 54px;
    margin: 0 20px;

    display: flex;
    justify-content: space-between;
    align-items: center;
    .ticket-review-data {
      .logo {
        padding-bottom: 15px;
        width: 100%;
        border-bottom: 1px solid #b5b5b5;
        img {
          width: 87px;
          object-fit: cover;
        }
      }
      .event-name {
        font-size: 16px;
        font-weight: 700;
        line-height: 24px;
        letter-spacing: 0.5px;
        color: #0046ac;
        padding: 15px 0 5px;
      }
      .personal-fullname {
        font-size: 24px;
        font-weight: 700;
        line-height: 36px;
        letter-spacing: 0.5px;
        color: #001d48;
        margin-bottom: 5px;
      }
      // .speciality {
      //   font-size: 14px;
      //   font-weight: 500;
      //   line-height: 21px;
      //   letter-spacing: 0.5px;
      //   color: #001d48;
      // }
      .general-info {
        font-size: 14px;
        font-weight: 500;
        line-height: 14px;
        color: #00275f;
        &:nth-child(4) {
          padding-top: 15px ;
        }
        &:nth-child(5) {
          padding: 2px 0 15px;
        }
        &:nth-child(6) {
          padding-bottom: 10px;
        }
        &:nth-child(7) {
          padding-bottom: 15px;
        }
        &:nth-child(8) {
          padding-top: 15px;
          border-top: 1px solid #b5b5b5;
        }
        .label {
          display: inline-block;
          color: #0046ac;
          margin-right: 10px;
        }
        .price,
        .money-type {
          font-weight: 700;
          letter-spacing: 0.5px;
        }
        .price {
          font-size: 16px;
          line-height: 24px;
        }
        .money-type {
          font-size: 14px;
          line-height: 21px;
        }
      }
    }

    .qr-body {
      width: 400px;
      border-left: 1px solid #0046ac;
      padding-left: 21px;
      margin-left: 21px;
      .qr-photo {
        display: flex;
        justify-content: center;
        .placeholder {
          display: inline-block;
          max-width: 200px;
          width: 100%;
          aspect-ratio: 1/1;
          @include loadingStye();
        }
        img {
          max-width: 200px;
          width: 100%;
          aspect-ratio: 1/1;
          object-fit: cover;
        }
      }
      .event-main-info {
        margin-top: 10px;
        .general-info {
          font-size: 14px;
          font-weight: 500;
          line-height: 14px;
          color: #00275f;
          margin-bottom: 3px;

          .label {
            display: inline-block;
            color: #0046ac;
            margin-right: 10px;
          }
        }
      }
    }

    .no-print {
      position: absolute;
      top: -38px;
      right: 0px;
      color: #0046ac;
      background-color: transparent;
      border: none;
      outline: none;
      cursor: pointer;
      font-size: 14px;
      font-weight: 600;
      line-height: 16px;
      text-decoration: underline;
      padding: 7px 14px;
      background-color: #fff;
      border-radius: 15px;
    }
  }
}

@media (max-width: 768px) {
  .ticket {
    // padding: 40px 0;

    @media print {
      height: 500px;
      width: 826px;
      width: 100%;
      justify-content: center;
      align-items: center;
    }
    align-items: flex-end;
    .ticket-body {
      padding: 25px 40px;
      // max-width: 350px;

      height: 70vh;
      width: 100%;
      border-radius: 20px;
      margin: 0;
      overflow-y: scroll;
      align-items: flex-end;
      &::-webkit-scrollbar-track {
        background-color: transparent;
      }
      &::-webkit-scrollbar {
        width: 3px;
      }
      &::-webkit-scrollbar-thumb {
        border-radius: 5px;
        background-color: #757575;
      }
      &::-webkit-scrollbar-track-piece:end {
        margin-bottom: 15px;
      }

      &::-webkit-scrollbar-track-piece:start {
        margin-top: 15px;
      }
      @media print {
        overflow-y: auto;
        width: 826px !important;
        height: 100%;
        flex-direction: row;
      }

      display: flex;
      flex-direction: column;
      align-items: center;

      .qr-body {
        width: 100%;
        border-top: 1px solid #0046ac;
        border-left: none;
        padding-top: 21px;
        margin-top: 21px;
        padding-left: 0;
        margin-left: 0;
        @media print {
          border-left: 1px solid #0046ac;
          border-top: none;
          padding-left: 21px;
          margin-left: 21px;
          padding-top: 0;
          margin-top: 0;
        }
      }
      .event-main-info {
        align-self: flex-end;
        @media print {
          justify-self: center;
          text-align: center;
        }
      }
      .no-print {
        top: 35px;
        right: 30px;
      }
    }
  }
}
@media print {
  //   body * {
  //     visibility: hidden;
  //   }
  body {
    width: 1440px;
  }
  .ticket,
  .ticket * {
    // visibility: visible;
    -webkit-print-color-adjust: exact; /* Safari */
    color-adjust: exact; /* Firefox and Edge */
    print-color-adjust: exact; /* Chrome */
  }
  .no-print {
    display: none;
  }
}
