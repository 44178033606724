@import "../../mixins";
.ticket-item-loader {
  padding: 6px 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 15px;
  border-bottom: 1px solid #e6edf7;
  &:last-child {
    border-bottom-color: transparent;
  }
  .info-area {
    max-width: 400px;
    width: 100%;
    height: 30px;
    @include loadingStye();
  }
  .watch-ticket {
    display: inline-block;
    width: 100px;
    height: 30px;
    @include loadingStye();
  }
}
