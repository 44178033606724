.partners-invoice {
  .container {
    .invoice {
      margin: 15px 0;
      background-color: #fff;
      border-radius: 12px;
      padding: 20px 30px;
      min-height: calc(100vh - 400px);
      .caption {
        font-size: 16px;
        font-weight: 600;
        line-height: 28px;
        color: #001d48;
        margin-bottom: 15px;
      }
      .inp-area {
        display: flex;
        justify-content: flex-start;
        flex-wrap: wrap;
        gap: 2%;
        row-gap: 25px;

        .form-group {
          border: 1px solid #b5b5b5;
          border-radius: 4px;
          position: relative;
          height: 40px;
          width: 31.6%;
          &.select-group {
            width: 100%;
          }

          .label {
            font-size: 12px;
            line-height: 10px;
            font-weight: 400;
            letter-spacing: 0.5px;
            z-index: 1;
            position: absolute;
            color: #434343;
            transition: 0.5s ease;
            transform: translate(16px, 14px);
            width: 90%;
            &.animate {
              width: auto;
              transform: translate(15px, -2px);
              background-color: #fff;
              font-size: 12px;
              line-height: 2px;
              color: #000;
              z-index: 4;
            }
          }
          .caption {
            font-size: 12px;
            font-weight: 400;
            line-height: 2px;
            letter-spacing: 0.5px;
            color: #000;
            z-index: 4;
            position: absolute;
            transform: translate(15px, -2px);
            background-color: #fff;
            color: #000;
          }
          input {
            padding: 11px 15px;
            width: 100%;
            height: 100%;
            background-color: transparent;
            outline: none;
            border: none;
            position: relative;
            z-index: 3;
            font-size: 14px;
            font-weight: 400;
            line-height: 21px;
            letter-spacing: 0.5px;
            color: #000;
            &[type="number"]::-webkit-inner-spin-button {
              -webkit-appearance: none;
            }
            &[type="date"] {
              cursor: pointer;
            }
            &[type="date"]::-webkit-calendar-picker-indicator {
              cursor: pointer;
              padding: 4px;
            }
            &:-webkit-autofill,
            &:-webkit-autofill:hover,
            &:-webkit-autofill:focus {
              -webkit-box-shadow: 0 0 0 1000px #ffffff inset;
              background-color: transparent !important;
            }
          }

          .error-message {
            font-size: 10px;
            font-weight: 600;
            line-height: 17px;

            color: #e83867;
            margin-bottom: 5px;
            padding-left: 3px;
          }
        }
      }
      .btn-area {
        width: 100%;
        .btn {
          border: none;
          outline: none;
          padding: 10px 26px;
          font-size: 14px;
          font-weight: 500;
          line-height: 16.67px;
          color: #fff;
          background-color: #0046ac;
          border-radius: 20px;
          text-align: center;
          cursor: pointer;
        }
      }
      .sub-caption {
        font-size: 14px;
        font-weight: 700;
        line-height: 28px;
        color: #001d48;
      }
    }
  }
}
@media (max-width: 1024px) {
  .partners-invoice {
    .container {
      .invoice {
        margin: 15px 0;
        padding: 20px 30px;
        min-height: calc(100vh - 400px);
        .caption {
          font-size: 14px;
        }
        .inp-area {
          gap: 3%;
          row-gap: 20px;

          .form-group {
            width: 48%;
          }
        }
        .btn-area {
          .btn {
            font-size: 12px;
          }
        }
      }
    }
  }
}
@media (max-width: 764px) {
  .partners-invoice {
    background-color: #fff;
    .container {
      .invoice {
        margin: 0;
        padding: 10px 0;
        min-height: auto;

        .inp-area {
          gap: 3%;
          row-gap: 20px;

          .form-group {
            width: 100%;
          }
        }
        .btn-area {
          .btn {
            width: 100%;
          }
        }
      }
    }
  }
}
