.sweetAlert-container {
  backdrop-filter: blur(4px);
  z-index: 99999999;
}
.your-title-style {
  font-size: 28px;
  color: #000;
}
.swal-close-button {
  position: absolute;
  top: 0;
  right: 0;
  margin: 12px;
  padding: 8px;
  font-size: 28px;
  color: #000;
  background-color: transparent;
  cursor: pointer;
  transition: 0.5s ease;
  &:hover {
    background-color: rgba(0, 0, 0, 0.05);
    color: #000;
  }
}
.sweetAlert-confirm-button {
  padding: 8px 24px;
  font-size: 15px;
  border-radius: 4px;
  color: #000;
  background-color: #fff;
  border: 3px solid #000;
  cursor: pointer;
  transition: 0.6s ease;
  &:hover {
    color: #fff;
    background-color: #000;
  }
}
