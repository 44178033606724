.payment {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
  background-color: #00000025;
//   opacity: 0;
//   z-index: 0;
//   visibility: hidden;
//   transition: 0.3s ease;
//   &.active {
//     opacity: 1;
//     z-index: 1;
//     visibility: visible;
//   }
  .payment-boy {
    padding: 20px;
    background-color: #fff;
    max-width: 330px;
    width: 100%;

 
    border-radius: 18px;
    text-align: center;
    .title {
      font-size: 20px;
      line-height: 18px;
      font-weight: 600;
      color: #0046ac;
      padding-right: 10px;
      margin-bottom: 20px;
    }
    .form-group {
      border: 1px solid #b5b5b5;
      border-radius: 4px;
      position: relative;
      height: 40px;
      width: 100%;
      margin-bottom: 20px;
      .label {
        font-size: 14px;
        line-height: 10px;
        font-weight: 400;
        letter-spacing: 0.5px;
        z-index: 1;
        position: absolute;
        color: #434343;
        transition: 0.5s ease;
        transform: translate(25px, 15px);
        &.animate {
          transform: translate(15px, -2px);
          background-color: #fff;
          font-size: 12px;
          line-height: 2px;
          color: #000;
          z-index: 4;
        }
      }

      input {
        padding: 11px 15px;
        width: 100%;
        height: 100%;
        background-color: transparent;
        outline: none;
        border: none;
        position: relative;
        z-index: 3;
        font-size: 14px;
        font-weight: 400;
        line-height: 21px;
        letter-spacing: 0.5px;
        color: #000;
        &[type="number"]::-webkit-inner-spin-button {
          -webkit-appearance: none;
        }
        &:-webkit-autofill,
        &:-webkit-autofill:hover,
        &:-webkit-autofill:focus {
          -webkit-box-shadow: 0 0 0 1000px #ffffff inset;
          background-color: transparent !important;
        }
      }

      .error-message {
        font-size: 10px;
        font-weight: 600;
        line-height: 17px;

        color: #e83867;
        margin-bottom: 5px;
        padding-left: 3px;
      }
    }
    .submit {
      border: none;
      outline: none;
      padding: 10px;
      font-size: 14px;
      font-weight: 500;
      line-height: 21px;
      letter-spacing: 0.5px;
      color: #fff;
      background-color: #0046ac;
      max-width: 100%;
      width: 100%;
      border-radius: 20px;
      text-align: center;
      cursor: pointer;
    }
    .alert{
      margin-top: 10px;
      font-size: 14px;
      font-weight: 500;
      line-height: 16.67px;
      color: #e83867;
  
    }
  }
}
@media (max-width: 575px) {
  .payment {
    align-items: flex-end;
    padding: 0;
    .payment-boy {
        max-width: 100%;
        // max-height: 280px;
        height: 40vh;
      border-radius: 18px 18px 0 0;
    .submit{
        max-width: 100%;
    }
    }
  }
}
