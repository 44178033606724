.information-area {
  width: 100%;
  padding: 25px 0;
  background-color: #fff;
  .container {
    .head {
      .title {
        font-size: 26px;
        font-weight: 600;
        line-height: 26px;
        color: #0046ac;
        margin-bottom: 12px;
      }
      .row {
        align-items: center;
        justify-content: space-between;
        .info {
          width: 54.9%;
          .text {
            width: 100%;
            font-weight: 500;
            line-height: 4px;
            color: #000;
            a,
            p,
            h1,
            h2,
            h3,
            h4,
            h5,
            h6,
            strong,
            li,
            ol,
            big {
              font-size: 14px;
              line-height: 18px;
            }
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 4;
            page-break-inside: avoid !important;
            white-space: pre-wrap !important;
            overflow: hidden;
            text-overflow: ellipsis;
            transition: 0.5s ease;
            text-wrap: wrap;
            transform-origin: top center;
            &.large-text {
              transform-origin: bottom center;
              -webkit-box-orient: vertical;
              overflow: visible;
              text-overflow: ellipsis;
              -webkit-line-clamp: unset;
              display: block;
              overflow: visible;
              text-overflow: unset;
            }
          }
          .read-more {
            display: inline-block;
            margin-top: 6px;
            font-size: 16px;
            font-weight: 400;
            line-height: 18px;
            color: #0046ac;
            text-decoration: underline;
            cursor: pointer;
            margin-bottom: 20px;
            &.isVisible {
              position: absolute;
              z-index: -1;
              visibility: hidden;
            }
          }
        }
        .logo {
          width: 28.5%;
          height: 100%;
          padding: 37px 0;
          img {
            max-width: 239px;
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
      }
    }
  }
  .body {
    position: relative;

    .container {
      .static {
        padding: 22px 0;
        box-shadow: 0px 0px 12px 0px #00000040;
        background-color: #fff;
        border-radius: 19px;
        position: sticky;
        z-index: 99;
        .row {
          align-items: center;
          .static-card {
            width: 32%;
            text-align: center;
            .number {
              font-size: 40px;
              font-weight: 900;
              line-height: 45px;
              color: #b5b5b5;
            }
            .title {
              font-size: 25px;
              font-weight: 600;
              line-height: 25px;
              color: #0046ac;
              margin-top: 5px;
            }
          }
          .divider {
            width: 0.2%;
            height: 76px;

            background: linear-gradient(
              180deg,
              rgba(0, 70, 172, 0.1) 0%,
              #0046ac 50.31%,
              rgba(0, 70, 172, 0.1) 100%
            );
          }
        }
      }
    }
    .line-area {
      position: absolute;
      top: 0;
      left: 0;
      display: flex;
      align-items: center;
      width: 100%;
      height: 100%;
      .line {
        width: 100%;
        height: 2px;
        background: linear-gradient(
          90deg,
          rgba(0, 70, 172, 0.03) 0.49%,
          #0046ac 50.33%,
          rgba(0, 70, 172, 0.03) 100%
        );
        z-index: 1;
      }
    }
  }
}
/* ------------------------------- Responsive ------------------------------- */
@media (max-width: 992px) {
  .information-area {
    .body {
      .container {
        .static {
          .row {
            .static-card {
              .number {
                font-size: 34px;
                line-height: 24px;
              }
              .title {
                margin-top: 23.2px;
                font-size: 22px;
                line-height: 24px;
              }
            }
            .divider {
              height: 76px;
            }
          }
        }
      }
    }
  }
}
@media (max-width: 575px) {
  .information-area {
    .container {
      .head {
        .title {
          font-size: 22px;
          line-height: 22px;
          margin-bottom: 16px;
        }
        .row {
          flex-direction: column-reverse;
          .info {
            width: 100%;
            .read-more {
              margin: 6px 0 61px;
              &.isVisible {
                margin: 0;
              }
            }
          }
          .logo {
            width: 100%;
            padding: 25px 0;
            img {
              display: inherit;
              margin: 0 auto;
            }
          }
        }
      }
    }
    .body {
      .container {
        .static {
          padding: 0;
          .row {
            flex-direction: column;
            justify-content: center;
            .static-card {
              width: 100%;
              height: 115px;
              display: flex;
              flex-direction: column;
              align-items: center;
              justify-content: center;
            }
            .divider {
              width: 100%;
              height: 2px;
              margin: 10px 0;
              background: linear-gradient(
                90deg,
                rgba(0, 70, 172, 0.1) 0.12%,
                #0e4cb1 49.6%,
                rgba(0, 70, 172, 0.1) 100.12%
              );
            }
          }
        }
      }
    }
  }
}
