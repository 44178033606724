.detail-search-area {
  width: 100%;
  height: 280px;
  background-color: #8aaad9;

  .container {
    height: 100%;
    .row {
      height: 100%;
      justify-content: center;
      align-items: center;
      .info {
        text-align: center;
        max-width: 660px;
        width: 100%;
        p,
        span {
          display: block;
        }
        .title {
          font-size: 30px;
          font-weight: 600;
          line-height: 36px;
          color: #fff;
          margin-bottom: 5px;
        }
        .subtitle {
          font-size: 16px;
          font-weight: 500;
          line-height: 19px;
          color: #fff;
          margin-bottom: 44px;
        }
        .search {
          height: 50px;

          width: 100%;
          display: flex;
          background-color: #fff;
          border-radius: 22px;
          box-shadow: 0px 0px 4px 0px #0046ac3b;
          overflow: hidden;
          .seacrh-icon {
            width: 65px;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
          }
          .seacrh-inp {
            width: 100%;
            height: 100%;
            border: none;
            outline: none;
            font-size: 14px;
            font-weight: 400;
            line-height: 18px;
            color: #000;
            &::placeholder {
              font-size: 14px;
              font-weight: 400;
              line-height: 18px;
              color: #d0d0d0;
            }
          }
        }
      }
    }
  }
}
@media (max-width: 992px) {
  .detail-search-area {
    .container {
      .row {
        .info {
          .title {
            font-size: 28px;
            line-height: 34px;
            margin-bottom: 3px;
          }
          .search {
            max-width: 456px;
            width: 100%;
            margin: 0 auto;
          }
        }
      }
    }
  }
}
@media (max-width: 575px) {
  .detail-search-area {
    height: min-content;
    .container {
      .row {
        .info {
          padding: 20px 0;
          .title {
            font-size: 22px;
            line-height: 27px;
          }
          .subtitle {
            margin-bottom: 20px;
            font-size: 14px;
            line-height: 22px;
          }
          .search {
            max-width: 100%;
            height: 44px;
            .seacrh-inp {
              padding-right: 20px;
            }
          }
        }
      }
    }
  }
}
