.header {
  width: 100%;
  background-color: #fff;
  .container {
    .row {
      justify-content: space-between;
      align-items: center;
    }
    .logo {
      padding: 8px 0;
      width: 87px;
    }
    .nav-bar {
      .nav-list {
        display: flex;
        .nav-items {
          margin: 10px 0;
          a {
            padding: 5px;
            font-weight: 600;
            color: #001d48;
            position: relative;
            &::after {
              content: "";
              position: absolute;
              bottom: 0;
              left: 45%;
              height: 5px;
              width: 10px;
              border-radius: 16px;
              background: #001d48;
              transform: scaleX(0);
              transform-origin: right center;
              transition: transform 0.6s cubic-bezier(0.19, 1, 0.22, 1);
            }
            &:hover {
              &::after {
                transition-duration: 0.4s;
                transform: scale(1);
                transform-origin: left center;
              }
            }
            &.active {
              &::after {
                transform: scaleX(1);
              }
            }
          }
        }
      }
    }
    .user-area {
      display: flex;
      align-items: center;
      font-weight: 500;
      font-size: 14px;
      .login {
        padding: 5px 18.67px;
        margin-left: 5px;
        color: #0046ac;
        font-size: 14px;
        font-weight: 500;
        line-height: 16px;
        cursor: pointer;
        display: flex;
        align-items: center;
        gap: 7px;
      }
      .profile {
        // width: 40px;
        // height: 40px;
        // border-radius: 100%;
        // overflow: hidden;
        cursor: pointer;
        display: flex;
        align-items: center;
        gap: 10px;
        // border: 2px solid #00d68f;
        .user-name {
          font-size: 14px;
          font-weight: 500;
          line-height: 16px;
          color: #0046ac;
        }
        .icon {
          img {
            display: flex;
            width: 44px;
            height: 44px;
            object-fit: cover;
          }
        }
      }
    }
    .mobile-menu {
      display: none;
      cursor: pointer;
      svg {
        font-size: 32px;
      }
    }
  }
  .form-area {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 15px;
    z-index: 999;
    background-color: rgba(0, 0, 0, 0.4);
    .form-box {
      z-index: 999;
      max-width: 550px;
      width: 100%;
      max-height: 620px;
      height: 100%;
      background-color: var(--white);
      border-radius: 18px;
      overflow: hidden;
      .head {
        width: 100%;
        display: flex;
        align-items: center;
        .title {
          font-size: 23px;
          width: 50%;
          text-align: center;
          padding: 12px 0;
          font-weight: 500;
          line-height: 34px;
          cursor: pointer;
          color: #0046ac;
          background-color: #f2f2f2;
          transition: 0.3s ease;
          transform: border scaleX(0);
          transform-origin: right center;
          transition: transform 0.6s cubic-bezier(0.19, 1, 0.22, 1);
          position: relative;
          &:first-child {
            border-top-left-radius: 17px;
            &::after {
              transform-origin: right center;
            }
            &.active {
              &::after {
                transform-origin: right center;
              }
            }
          }
          &:last-child {
            border-top-right-radius: 17px;
            &::after {
              transform-origin: left center;
            }
            &.active {
              &::after {
                transform-origin: left center;
              }
              background-color: var(--white);
            }
          }
          &::after {
            content: "";
            position: absolute;
            bottom: 0;
            left: 0;
            height: 2px;
            width: 100%;
            background: #0046ac;
            transform: scaleX(0);
            transition: transform 0.6s cubic-bezier(0.19, 1, 0.22, 1);
          }
          &.active {
            &::after {
              transform: scaleX(1);
            }
            background-color: var(--white);
          }
        }
      }
      .body {
        border-bottom-right-radius: 18px;
        border-bottom-left-radius: 18px;
        position: relative;
      }
    }
  }

  .overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    z-index: 998;
    background-color: transparent;
    visibility: hidden;
    opacity: 0;
    transition: 0.2s ease;
    &.isOpen {
      visibility: visible;
      opacity: 1;
      background-color: rgba(0, 0, 0, 0.4);
    }
  }
}

@media (max-width: 992px) {
  .header {
    .container {
      .row {
        .mobile-menu {
          display: flex;
          padding: 5px;
        }
        .nav-bar {
          display: none;
        }
        .user-area {
          .language {
            display: none;
          }
          .login {
            padding: 5px;
          }
        }
      }
    }
  }
}
@media (max-width: 575px) {
  .header {
    .container {
      width: 100%;
      min-width: auto;
      .user-area{
        .profile{
          .user-name{
            display: none;
          }
        }
      }
      .mobile-menu {
        svg {
          width: 20px;
        }
      }
    }
    .form-area {
      align-items: flex-end;
      padding: 0;
      .form-box {
        border-radius: 18px 18px 0 0;
      }
    }
  }
}
@media print {
  .header {
    display: none;
  }
}
