.subscibe-partners {
  background: #8aaad9;

  padding: 110px 0;
  .container {
    .subscribe-content {
      background: #001d48;
      box-shadow: 0px 0px 43.1px 17px #ffffff75;
      border-radius: 25px;
      padding: 40px 70px;
      .subscribe-card-area {
        margin-top: -120px;
        .swiper-wrapper {
          .swiper-slide {
            &.swiper-slide-thumb-active {
              .subscribe-card {
                background-color: #0046ac;
                .packet-name {
                  color: #fff;
                }
              }
            }
            .subscribe-card {
              position: relative;
              background-color: #fff;
              width: 100%;
              aspect-ratio: 1/1.7;
              border-radius: 20px;
              overflow: hidden;
              cursor: pointer;
              transition: 0.5s ease;
              .pattern-img {
                position: absolute;
                width: 100%;
                height: 100%;
                display: flex;
                img {
                  width: 100%;
                }
              }
              .pattern-top {
                top: -10px;
                justify-content: flex-end;
                align-items: flex-start;
              }
              .pattern-bottom {
                justify-content: flex-start;
                align-items: flex-end;
                bottom: -10px;
                img {
                  rotate: (180deg);
                }
              }
              .price-info {
                position: absolute;
                width: 100%;
                display: flex;
                justify-content: flex-end;
                flex-direction: column;
                align-items: flex-end;
                z-index: 1;
                padding: 20px;
                color: #334155;
                .caption-free {
                  font-size: clamp(14px, 2.5vw, 24px);
                  font-weight: 700;
                  line-height: 19.6px;
                  color: inherit;
                }
                .price {
                  font-size: clamp(24px, 4.5vw, 40px);
                  font-weight: 700;
                  line-height: 35.28px;
                  color: inherit;
                  text-wrap: nowrap;
                  .currency {
                    font-size: clamp(14px, 1.5vw, 18px);
                    font-weight: 500;
                    line-height: 18px;
                    color: #334155;
                    padding-right: 2px;
                  }
                }
                .subcaption {
                  font-size: clamp(12px, 2.5vw, 14px);
                  font-weight: 400;
                  line-height: 18px;
                  color: inherit;
                  padding-top: 6px;
                }
              }
              .packet-name {
                position: absolute;
                width: 100%;
                height: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: clamp(14px, 2.5vw, 24px);
                font-weight: 600;
                line-height: 32px;
                color: #0046ac;
                z-index: 1;
              }
              .read-more {
                position: absolute;
                width: 100%;
                height: 100%;
                display: flex;
                justify-content: flex-end;
                align-items: flex-end;
                padding: 20px;
                font-size: clamp(12px, 2.5vw, 14px);
                font-weight: 400;
                line-height: 18px;
                color: #334155;
                text-decoration: underline;
                z-index: 1;
              }
            }
          }
        }
      }
    }
    .detail-slider {
      padding-top: 40px;
      .swiper-wrapper {
        .swiper-slide {
          .info-content {
            .caption {
              font-size: 16px;
              font-weight: 400;
              line-height: 18px;
              color: #ffffff;
              margin-bottom: 13px;
            }
            .plan-list {
              color: #b9b9b9;
              padding-left: 20px;
              .plan-item {
                margin-bottom: 13px;
                list-style-type: disc;

                .detail {
                  font-size: 14px;
                  font-weight: 400;
                  line-height: 18px;
                }
                .sub-list {
                  padding-left: 20px;
                  margin-top: 4px;
                  .sub-item {
                    list-style-type: "-";
                    font-family: Poppins;
                    font-size: 14px;
                    font-weight: 400;
                    line-height: 18px;
                  }
                }
              }
            }
            .btn-area {
              width: 100%;
              text-align: right;
              .subscribe {
                font-size: 14px;
                font-weight: 500;
                line-height: 16.67px;
                color: #ffffff;
                padding: 10px 26px;
                background: #0046ac;
                border-radius: 19px;
                cursor: pointer;
                border: none;
                outline: none;
              }
            }
          }
        }
      }
      .navigate-area {
        position: absolute;
        top: 5px;
        left: 0;
        display: flex;
        width: 100%;
        justify-content: center;
        gap: 10px;
        align-items: center;
        opacity: 0;
        visibility: hidden;
        .navigate-text {
          font-size: 14px;
          font-weight: 500;
          line-height: 32px;
          color: #ffffff;
          text-wrap: nowrap;
        }
        button {
          background-color: transparent;
          border: none;
          outline: none;
          cursor: pointer;
          img {
            display: flex;
          }
        }
      }
    }
  }
}
@media (max-width: 992px) {
  .subscibe-partners {
    padding: 110px 0;
    .container {
      .subscribe-content {
        padding: 20px;
        .subscribe-card-area {
          margin-top: -100px;
          .swiper-wrapper {
            .swiper-slide {
              .subscribe-card {
                width: 100%;
                aspect-ratio: 1/1.7;
                height: 300px;
                border-radius: 20px;
                overflow: hidden;
                cursor: pointer;
                transition: 0.5s ease;
                .pattern-img {
                  position: absolute;
                  width: 220px;
                  width: 100%;
                  height: 100%;
                  display: flex;
                  img {
                    width: 220px;
                  }
                }
                .pattern-top {
                  top: -10px;
                  justify-content: flex-end;
                  align-items: flex-start;
                }
                .pattern-bottom {
                  justify-content: flex-start;
                  align-items: flex-end;
                  bottom: -10px;
                  img {
                    rotate: (180deg);
                  }
                }
                .price-info {
                  position: absolute;
                  width: 100%;
                  display: flex;
                  justify-content: flex-end;
                  flex-direction: column;
                  align-items: flex-end;
                  z-index: 1;
                  padding: 10px;
                  color: #334155;
                  .caption-free {
                    font-size: clamp(14px, 2.5vw, 20px);
                    font-weight: 700;
                    line-height: 19.6px;
                    color: inherit;
                  }
                  .price {
                    font-size: clamp(24px, 4.5vw, 30px);
                    font-weight: 700;
                    line-height: 35.28px;
                    color: inherit;
                    text-wrap: nowrap;
                    .currency {
                      font-size: clamp(14px, 1.5vw, 18px);
                      font-weight: 500;
                      line-height: 18px;
                      color: #334155;
                      padding-right: 2px;
                    }
                  }
                  .subcaption {
                    font-size: clamp(12px, 2.5vw, 14px);
                    font-weight: 400;
                    line-height: 18px;
                    color: inherit;
                    padding-top: 6px;
                  }
                }
                .packet-name {
                  position: absolute;
                  width: 100%;
                  height: 100%;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  font-size: clamp(14px, 2.5vw, 24px);
                  font-weight: 600;
                  line-height: 32px;
                  color: #0046ac;
                  z-index: 1;
                }
                .read-more {
                  position: absolute;
                  width: 100%;
                  height: 100%;
                  display: flex;
                  justify-content: flex-end;
                  align-items: flex-end;
                  padding: 20px;
                  font-size: clamp(12px, 2.5vw, 14px);
                  font-weight: 400;
                  line-height: 18px;
                  color: #334155;
                  text-decoration: underline;
                  z-index: 1;
                }
              }
            }
          }
        }
      }
      .detail-slider {
        padding-top: 40px;
        .swiper-wrapper {
          .swiper-slide {
            .info-content {
              .caption {
                font-size: 16px;
                font-weight: 400;
                line-height: 18px;
                color: #ffffff;
                margin-bottom: 13px;
              }
              .plan-list {
                color: #b9b9b9;
                padding-left: 20px;
                .plan-item {
                  margin-bottom: 13px;
                  list-style-type: disc;

                  .detail {
                    font-size: 14px;
                    font-weight: 400;
                    line-height: 18px;
                  }
                  .sub-list {
                    padding-left: 20px;
                    margin-top: 4px;
                    .sub-item {
                      list-style-type: "-";
                      font-size: 14px;
                      font-weight: 400;
                      line-height: 18px;
                    }
                  }
                }
              }
              .btn-area {
                width: 100%;
                text-align: right;
                .subscribe {
                  font-size: 14px;
                  font-weight: 500;
                  line-height: 16.67px;
                  color: #ffffff;
                  padding: 10px 26px;
                  background: #0046ac;
                  border-radius: 19px;
                  cursor: pointer;
                  border: none;
                  outline: none;
                }
              }
            }
          }
        }
        .navigate-area {
          opacity: 1;
          visibility: visible;
        }
        .swiper-button-prev,.swiper-button-next{
            visibility: hidden;
            opacity: 0;
        }
      }
    }
  }
}
