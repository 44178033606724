.profile-content {
  padding: 10px 0px;
  min-height: 70vh;

  .container {
    position: relative;
    .profile-body {
      width: 100%;
      display: inline-block;
      padding-left: 220px;
    }
  }
}
@media (max-width: 992px) {
  .profile-content {
    background-color: #fff;
    min-height: 100%;
    .container {
      .profile-navigate {
        display: none;
      }
      .profile-body {
        padding-left: 0;
      }
    }
  }
}
