.iscomming {
  width: 100%;
  height: 100vh;
  .container {
    height: 100%;
    .row{
        width: 100%;
        height: 100%;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        .logo{
            max-width: 200px;
            width: 100%;
            img{
                width: 100%;
                object-fit: contain;
            }
        }
        .text{
font-size: 16px;
font-weight: 500;
line-height: 22px;
color: #001d48;
text-align: center;
        }
    }
  }
}
