.side-bar {
  position: fixed;
  top: 0;
  left: 0;
  max-width: 400px;
  height: 100vh;
  background-color: #fff;

  width: 80%;
  position: fixed;
  top: 0;
  left: 0;
  transform: translateX(-100%);
  visibility: hidden;
  opacity: 0;
  z-index: 99999;
  transition: 0.4s ease-in;
  &.isOpenMenu {
    transform: translateX(0%);
    opacity: 1;
    visibility: visible;
  }

  .mobile-container {
    padding: 0 20px;
    .head {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      align-items: center;
      padding: 12px 0;
      margin: 6px 0;
      border-bottom: 1px solid #f1f5f9;
      .logo {
        img {
          width: 87px;
          object-fit: cover;
        }
      }
      .close-icon {
        cursor: pointer;
        img {
          display: flex;
          width: 40px;
          height: 40px;
        }
      }
    }
    .body {
      margin-bottom: 20px;
      .user-info {
        display: flex;
        align-items: center;
        gap: 10px;
        margin: 10px 0;
        .user-icon {
          img {
            display: flex;
            width: 44px;
            height: 44px;
            object-fit: cover;
          }
        }
        .user-fullname {
          font-size: 14px;
          font-weight: 500;
          line-height: 16px;
          color: #0046ac;
        }
      }
      .mobile-list {
        display: flex;
        flex-direction: column;
        gap: 10px;
        border-bottom: 1px solid #b5b5b5;
        .mobile-items {
          .mobile-nav {
            font-size: 15px;
            font-weight: 500;
            line-height: 18px;
            color: #001d48;
            display: inline-block;
            width: 100%;
            padding: 10px;
            border-radius: 11px;

            &.active {
              background-color: #e6edf7;
            }
          }
        }
      }
      .language {
        margin: 21px;
        margin-top: 12px;
        cursor: pointer;
        height: 100%;
        display: flex;
        align-items: center;
        gap: 7px;
        .icon {
          display: flex;
          align-self: center;
        }
        .lang-icon {
          svg {
            width: 18px;
          }
        }
        .curent-lang {
          line-height: 14px;
          color: inherit;
          position: relative;
          .lang-list {
            position: absolute;
            top: 25px;
            left: -25px;
            width: 140px;
            border: 0.5px solid #0046ac;
            background-color: #fff;
            border-radius: 4px;
            overflow: hidden;
            visibility: hidden;
            opacity: 0;
            height: 0;
            transform: translateY(-10px);
            transition: 0.5s ease;
            &.active {
              opacity: 1;
              visibility: visible;
              transform: translateY(0);
              // height: 86px;
              height: 55px;
            }
            .lang-item {
              width: 100%;
              padding: 4px 6px;
              font-size: 14px;
              font-weight: 500;
              line-height: 18px;
              color: inherit;
              background-color: transparent;
              transition: 0.5s ease;
              &:hover {
                background-color: #ededed;
              }
            }
          }
        }
        .drop-icon {
          svg {
            width: 18px;
          }
          transform: rotate(0deg);
          transition: 0.5s ease;
          &.active-icon {
            transform: rotate(180deg);
          }
        }
      }
      .logout {
        width: 100%;
        padding: 13px;
        text-align: center;
        font-size: 16px;
        font-weight: 500;
        line-height: 22px;
        letter-spacing: -0.01em;
        color: #0f172a;
        background-color: transparent;
        border: 1px solid #0046ac;
        box-shadow: 0px 1px 2px 0px #0f172a0f;
        border-radius: 8px;
        outline: none;
        cursor: pointer;
      }
    }
  }
}
@media (max-width: 575px) {
  .side-bar {
    width: 100%;

    .mobile-container {
      padding: 0 20px;
      .head {
        .logo {
          img {
            width: 65px;
          }
        }
        .close-icon {
          img {
            display: flex;
            width: 35px;
            height: 35px;
          }
        }
      }
      .body {
        margin-bottom: 20px;
        .user-info {
          display: flex;
          align-items: center;
          gap: 10px;
          margin: 10px 0;
          .user-icon {
            img {
              width: 40px;
              height: 40px;
            }
          }
        }

        .logout {
          font-size: 14px;
          padding: 8px;
        }
      }
    }
  }
}
