.personal-information {
  width: 100%;
  padding: 0 20px;
  background-color: #fff;
  border-radius: 5px;
  min-height: 67vh;

  .profil-form {
    .inp-area {
      padding: 20px 0;

      max-width: 775px;
      width: 100%;
      margin: 0 auto;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      row-gap: 25px;
      .form-group {
        border: 1px solid #b5b5b5;
        border-radius: 4px;
        position: relative;
        height: 40px;
        width: 31.6%;
        &.select-group {
          width: 100%;
        }

        .label {
          font-size: 14px;
          line-height: 10px;
          font-weight: 400;
          letter-spacing: 0.5px;
          z-index: 1;
          position: absolute;
          color: #434343;
          transition: 0.5s ease;
          transform: translate(25px, 15px);
          &.animate {
            transform: translate(15px, -2px);
            background-color: #fff;
            font-size: 12px;
            line-height: 2px;
            color: #000;
            z-index: 4;
          }
        }
        .caption {
          font-size: 12px;
          font-weight: 400;
          line-height: 2px;
          letter-spacing: 0.5px;
          color: #000;
          z-index: 4;
          position: absolute;
          transform: translate(15px, -2px);
          background-color: #fff;
          color: #000;
        }
        input {
          padding: 11px 15px;
          width: 100%;
          height: 100%;
          background-color: transparent;
          outline: none;
          border: none;
          position: relative;
          z-index: 3;
          font-size: 14px;
          font-weight: 400;
          line-height: 21px;
          letter-spacing: 0.5px;
          color: #000;
          &[type="number"]::-webkit-inner-spin-button {
            -webkit-appearance: none;
          }
          &[type="date"] {
            cursor: pointer;
          }
          &[type="date"]::-webkit-calendar-picker-indicator {
            cursor: pointer;
            padding: 4px;
          }
          &:-webkit-autofill,
          &:-webkit-autofill:hover,
          &:-webkit-autofill:focus {
            -webkit-box-shadow: 0 0 0 1000px #ffffff inset;
            background-color: transparent !important;
          }
        }
        .select {
          width: 100%;
          height: 100%;
          display: flex;
          justify-content: space-between;
          align-items: center;
          position: relative;
          cursor: pointer;
          input {
            cursor: pointer;
          }
          .icon {
            display: flex;
            margin-right: 14px;
          }
        }
        .select-text {
          position: absolute;
          top: 45px;
          left: 0;
          width: 100%;
          border: 1px solid #b5b5b5;
          background-color: #fff;
          border-radius: 5px;
          overflow: hidden;
          z-index: 5;
          transform: translateY(-10px);
          visibility: hidden;
          opacity: 0;
          height: 0;
          transition: 0.4s ease;
          &.active {
            height: 200px;
            opacity: 1;
            visibility: visible;
            transform: translateY(0px);
          }
          .select-list {
            background-color: #fff;
            max-height: 200px;
            .select-item {
              cursor: pointer;
              font-size: 12px;
              line-height: 12px;
              letter-spacing: 0.5px;
              color: #434343;
              padding: 14px;
              transition: 0.3s ease;
              width: 100%;
              &:hover {
                background: #e1e1e1;
              }
              &.active {
                background: #e1e1e1;
              }
            }
          }

          // padding:14px ;
        }
        .error-message {
          font-size: 10px;
          font-weight: 600;
          line-height: 17px;

          color: #e83867;
          margin-bottom: 5px;
          padding-left: 3px;
        }
      }
    }
    .btn-area {
      max-width: 775px;
      margin: 0 auto;
      width: 100%;
      display: flex;
      justify-content: flex-end;
      gap: 15px;
      flex-wrap: wrap;
      .btn {
        border: none;
        outline: none;
        padding: 10px;
        font-size: 14px;
        font-weight: 500;
        line-height: 21px;
        letter-spacing: 0.5px;
        color: #fff;
        background-color: #0046ac;
        max-width: 216px;
        width: 100%;
        border-radius: 20px;
        text-align: center;
        cursor: pointer;
        margin-bottom: 10px;
      }
    }
  }
  .change-password {
    visibility: hidden;
    opacity: 0;
    transform: translateY(-70px);
    transition: 0.4s ease;
    padding-bottom: 20px;
    &.active {
      opacity: 1;
      visibility: visible;
      transform: translateY(0px);
    }
  }
}

@media (max-width: 992px) {
  .personal-information {
    // padding: 0;
    min-height: 100%;
    background-color: #fff;
    .profil-form {
      .inp-area {
        max-width: 696px;
        row-gap: 35px;
        .form-group {
          width: 48.5%;
          .label {
            font-size: 14px;
            line-height: 10px;
            font-weight: 400;
            letter-spacing: 0.5px;
            z-index: 1;
            position: absolute;
            color: #434343;
            transition: 0.5s ease;

            &.animate {
              transform: translate(15px, -2px);
              background-color: #fff;
              font-size: 12px;
              line-height: 2px;
              color: #000;
            }
          }
          .caption {
            font-size: 12px;
            font-weight: 400;
            line-height: 2px;
            letter-spacing: 0.5px;
            color: #000;
          }
          input {
            padding: 11px 20px;
            width: 100%;
            height: 100%;
            background-color: transparent;
            outline: none;
            border: none;
            position: relative;
            z-index: 3;
          }
        }
      }
    }
  }
}
@media (max-width: 550px) {
  .personal-information {
    padding: 0;
    background-color: #fff;
    .profil-form {
      .inp-area {
        max-width: 696px;
        .form-group {
          width: 100%;
          .label {
            font-size: 14px;
            line-height: 10px;
            font-weight: 400;
            letter-spacing: 0.5px;
            z-index: 1;
            position: absolute;
            color: #434343;
            transition: 0.5s ease;

            &.animate {
              transform: translate(15px, -2px);
              background-color: #fff;
              font-size: 12px;
              line-height: 2px;
              color: #000;
            }
          }
          .caption {
            font-size: 12px;
            font-weight: 400;
            line-height: 2px;
            letter-spacing: 0.5px;
            color: #000;
          }
          input {
            padding: 11px 20px;
            width: 100%;
            height: 100%;
            background-color: transparent;
            outline: none;
            border: none;
            position: relative;
            z-index: 3;
          }
        }
      }
      .btn-area {
        .btn {
          max-width: 100%;
        }
      }
    }
  }
}
