@import "../mixins";
.innovation {
  width: 100%;
  .container {
    .caption {
      @include caption();
    }
    .row {
      flex-wrap: wrap;
      justify-content: space-between;
      row-gap: 20px;
      margin-bottom: 15px;
      .innovation-card {
        height: 316px;
        border-radius: 7px;
        overflow: hidden;
        width: 49%;
        position: relative;
        border: none;
        outline: none;
        &:hover {
          .overlay-text {
            opacity: 1;
            visibility: visible;
            background-color: rgb(255 255 255 / 80%);
          }
          .card-info {
            opacity: 0;
          }
        }
        .inovation-img {
          width: 100%;
          height: 100%;
          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
        .overlay {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background: linear-gradient(
            180deg,
            rgba(0, 0, 0, 0.2) 0%,
            rgba(0, 0, 0, 0.8) 100%
          );
          z-index: 1;
        }
        .card-info {
          align-items: flex-start;
          justify-content: flex-end;
          flex-direction: column;
          z-index: 2;
          padding: 20px;
          color: #fff;
          transition: 0.5s ease;
          .title {
            padding: 4px 0;
          }
        }
        .status {
          justify-content: flex-end;
          align-items: flex-start;
          z-index: 4;

          .text {
            font-size: 12px;
            font-weight: 400;
            line-height: 18px;
            color: #fff;
            background: #0046ac;
            padding: 7px 21px;
            border-bottom-left-radius: 7px;
          }
        }
        .overlay-text {
          width: 100%;
          align-items: center;
          justify-content: center;
          z-index: 3;
          padding: 20px;
          opacity: 0;
          visibility: hidden;
          transition: 0.5s ease;
          .info {
            width: 100%;
            .date {
              color: #828282;
            }
            .title {
              color: #000;
              margin: 4px 0;
            }
            .personal-name {
              color: #000;
            }
            .text {
              font-size: 14px;
              font-weight: 400;
              line-height: 21px;
              color: #4f4f4f;
              margin-bottom: 14px;
              display: -webkit-box;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 3;
              overflow: hidden;
              text-overflow: ellipsis;
              width: 100%;
              page-break-inside: avoid;
              white-space: pre-wrap;
              white-space: -moz-pre-wrap;
              white-space: -pre-wrap;
              white-space: -o-pre-wrap;
            }
            .read-more {
              font-size: 12px;
              font-weight: 400;
              line-height: 18px;
              color: #0e3b3e;
              text-decoration: underline;
            }
          }
        }
        /* ------------------------------ Repeat Design ----------------------------- */
        .overlay,
        .card-info,
        .overlay-text,
        .status {
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
          display: flex;
        }
        .card-info,
        .overlay-text {
          .date {
            font-size: 10px;
            font-weight: 400;
            line-height: 15px;
          }
          .title {
            font-size: 17px;
            font-weight: 700;
            line-height: 26px;
          }
          .personal-name {
            font-size: 12px;
            font-weight: 400;
            line-height: 18px;
          }
        }
      }
    }
    .pagination {
      @include pagination();
    }
  }
}
/* ------------------------------- Responsive ------------------------------- */
@media (max-width: 992px) {
  .innovation {
    width: 100%;
    .container {
      .caption {
        padding: 16px 0;
        font-size: 18px;
        line-height: 18px;
      }
    }
  }
}
@media (max-width: 575px) {
  .innovation {
    width: 100%;
    .container {
      .caption {
        padding: 12px 0;
        font-size: 16px;
        line-height: 20px;
      }
      .row {
        .innovation-card {
          height: 300px;
          width: 100%;
        }
      }
    }
  }
}
