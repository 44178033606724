// Calendar select area
.calendar-range {
  display: none;
}
.reset-date{
  width: 100%;
  display: flex;
  justify-content: flex-end;
  button{
font-size: 14px;
font-weight: 500;
line-height: 14px;
color: #00528C;
margin: 10px 0;
cursor: pointer;
border: none;
text-decoration: underline;
  }
}
// List
.event-info {
  background-color: #fff;
  height: 100vh;
  padding: 13px;
  border-radius: 10px;
  margin-top: 10px;

  .head {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 13px;

    .caption {
      font-size: 12px;
      font-weight: 700;
      line-height: 15.67px;
      color: #0095ff;
    }
    .download-pdf {
      font-size: 10px;
      font-weight: 400;
      line-height: 15.67px;
      color: #0095ff;
      text-decoration: underline;
    }
  }
  .list {
    .list-item {
      cursor: pointer;
      margin: 13px 0;
      .date {
        font-size: 10px;
        font-weight: 700;
        line-height: 8.17px;
        color: #00528c;
        margin-bottom: 13px;
      }
      .hour {
        font-size: 10px;
        font-weight: 400;
        line-height: 8.17px;
        color: #00528c;
      }
      .event-name {
        margin: 7px 0;
        padding: 6px;
        border-radius: 5px;
        border-left: 5px solid #9be80c;
        font-size: 12px;
        font-weight: 600;
        line-height: 18px;
        color: #000000;
        box-shadow: 0px 3px 6px -2px #0046ac38;
        a {
          text-wrap: nowrap;
          text-decoration: underline;
          color: #1a00d6;
        }
      }
      .alert {
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 14px;
        font-weight: 500;
        line-height: 16.67px;
        color: #7f8ea3;
      }
    }
  }
}

// Mini Calendar
.mobile-calendar-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: #00000025;
  z-index: 999;
  display: flex;
  align-items: flex-end;
  .mini-calendar {
    height: 60vh;
    background-color: #fff;
    padding: 20px;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    .react-calendar {
      border: none;
      .react-calendar__viewContainer {
        .react-calendar__month-view {
          .react-calendar__month-view__weekdays {
            font-size: 14px;
            font-weight: 400;
            line-height: 24px;
            text-align: center;
            color: #2d334a;
          }
          .react-calendar__month-view__days {
            button {
              font-size: 16px;
              font-weight: 400;
              line-height: 24px;
              color: #2d334a;
            }
            button:disabled,
            button[disabled] {
              color: #9ea1ac;
              background-color: transparent;

              // border: 1px solid #999999;
              // background-color: #cccccc;
              // color: #666666;
            }
            .react-calendar__tile--now {
              background-color: #cccccc52;
            }
            .react-calendar__tile--active {
              background: #3d0700;
              color: #fff;
              font-size: 16px;
              font-weight: 700;
              line-height: 24px;
              text-align: center;
              border-radius: 5px;
            }
          }
        }
      }
    }
  }
}
@media (max-width: 1024px) {
  .event-info {
    height: 100%;
  }
}
@media (max-width: 768px) {
  .calendar-range {
    display: flex;
    align-items: center;
    width: 100%;
    background: #fff;
    box-shadow: 0px 0px 23.1px -5px #00000040;
    border-radius: 15px;
    padding: 20px;
    .calendar-group {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 15px;
      width: 100%;
      cursor: pointer;
      &:first-child {
        border-right: 1px solid #c3b2b0;
      }
      .icon {
        img {
          display: flex;
          width: 24px;
          height: 24px;
          object-fit: cover;
        }
      }
      .info-area {
        .caption {
          font-size: 14px;
          font-weight: 500;
          line-height: 20px;
          color: #2d334a;
        }
        .date {
          font-size: 12px;
          font-weight: 400;
          line-height: 16px;
          color: #727686;
        }
      }
    }
  }
}
