.not-found-page {
  width: 100%;
  .container {
    .bg-img {
      width: 100%;
      position: relative;
      img {
        width: 100%;
        object-fit: contain;
      }
      .btn-area {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: flex-end;
        a {
          display: flex;
          align-items: center;
          gap: 7px;
          font-size: 20px;
          font-weight: 400;
          line-height: 30px;
          color: #001d48;
          margin-bottom: 60px;
          text-align: center;
          position: relative;
          &::before {
            content: "";
            position: absolute;
            top: 30px;
            left: 25%;
            display: flex;
            justify-content: center;
            width: 60%;
            height: 2px;
            background: #001d48;
          }
        }
      }
    }
  }
}
@media (max-width: 992px) {
  .not-found-page {
    width: 100%;
    .container {
      .bg-img {
        width: 100%;
        position: relative;
        img {
          width: 100%;
          object-fit: contain;
        }
        .btn-area {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: flex-end;
          svg{
            width: 16px;
          }
          a {
            gap: 4px;
            font-size: 11px;
            line-height: 17px;
            margin-bottom: 30px;

            &::before {
              height: 1px;
            }
          }
        }
      }
    }
  }
}
