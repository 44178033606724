.change-password-area {
  max-width: 380px;
  margin: 0 auto;
  padding: 25px 0;
  .change-pass-title {
    font-size: 24px;
    font-weight: 600;
    line-height: 36px;
    color: #001d48;
  }
  .change-pass-text {
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    letter-spacing: 0.63px;
    color: #001d48;
    padding: 6px 0 15px;
  }
  .change-pass-form {
    .form-group {
      width: 100%;
      position: relative;
      label {
        display: block;
        font-size: 13px;
        font-weight: 600;
        line-height: 21px;
        padding-bottom: 3px;
        color: #001d48;
      }
      input {
        width: 100%;
        height: 40px;
        border: 1px solid #b0b0b0;
        background-color: transparent;
        outline: none;
        border-radius: 9px;
        width: 100%;
        padding: 0 7px;
        font-size: 14px;
        color: #000;
        font-weight: 500;
        transition: 0.3s ease;
        &[type="password"] {
          padding-right: 40px;
        }
        &:focus {
          border-color: #0046ac;
        }
        &[type="checkbox"] {
          display: inline-block;
          background-color: #0046ac;
          margin-top: 3px;
          width: auto;
          height: auto;
          cursor: pointer;
        }
        &.error {
          border-color: #e83867;
        }
      }
      svg {
        display: block;
        position: absolute;
        top: 35px;
        right: 15px;
        font-size: 16px;
        color: #545454;
        cursor: pointer;
      }
    }
    .error-message {
      color: #e83867;
      padding: 3px 10px;
      font-size: 11px;
      font-weight: 600;
      line-height: 18px;
    }
    .btn {
      display: block;
      text-align: center;
      width: 100%;
      color: #fff;
      background-color: var(--blue-light);
      margin: 15px 0;
      border: none;
      outline: none;
      font-size: 16px;
      font-weight: 500;
      line-height: 23px;
      border-radius: 28px;
      padding: 11px;
      cursor: pointer;
    }
  }
}
@media (max-width: 575px) {
  .change-password-area {
    padding: 25px 16px;
  }
}
