@import "../mixins";
.general-card-loading {
  min-height: 348px;
  border-radius: 15px;
  .top {
    background-color: #fff;
    padding: 10px 15px;
    font-weight: 500;
    color: #001d48;
    border-top-right-radius: 15px;
    border-top-left-radius: 15px;
    min-height: 65px;

    .title {
      max-width: 100px;
      height: 20px;
      @include loadingStye();
    }
  }
  .middle {
    width: 100%;
    width: 100%;
    @include loadingStye();
    height: 136px;
  }
  .bottom {
    background-color: #fff;
    padding: 10px 15px;
    min-height: 150px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border-bottom-right-radius: 15px;
    border-bottom-left-radius: 15px;
    .info {
      .title {
        max-width: 100px;
        height: 20px;
        @include loadingStye();
      }
      .date {
        margin-top: 10px;
        max-width: 150px;
        height: 20px;
        @include loadingStye();
      }
    }
    .company-name {
      max-width: 200px;
      height: 20px;
      @include loadingStye();
    }
  }
}
@media (max-width: 575px) {
  .general-card-loading {
    min-height: 244px;
    border-radius: 15px;
    .top {
      padding: 7px 10px;
    }
    .middle {
      height: 95px;
    }
    .bottom {
      padding: 7px 10px;
      min-height: 105px;
    }
  }
}
