// Caption
@mixin caption {
  padding: 15px 0;
  font-size: 20px;
  font-weight: 600;
  line-height: 20px;
  color: #0046ac;
}
//  Btn
@mixin btn($mg, $maxwdth, $pdg) {
  display: inline-block;
  margin: $mg;
  box-shadow: 0px 5px 15px -2px rgba(0, 70, 172, 0.2784313725);
  background-color: #0046ac;
  color: #fff;
  padding: $pdg;
  font-size: 14px;
  font-weight: 500;
  line-height: 17px;
  border-radius: 19px;
  max-width: $maxwdth;
  width: 100%;
  cursor: pointer;
}

// Pagination
@mixin pagination() {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  margin: 20px 0 15px;
  button {
    width: 30px;
    height: 30px;
    outline: none;
    border: none;
    padding: 3px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    background-color: transparent;
    transition: 0.5s ease;
  }
  .prev-number,
  .active,
  .next-number {
    font-size: 16px;
    font-weight: 500;
    line-height: 16px;
    color: #001d48;
  }
  .active {
    font-size: 20px;
    font-weight: 500;
    line-height: 20px;
    color: #ad471f;
  }

  .next:disabled,
  .next[disabled] {
    opacity: 0.5;
  }
}

@mixin specialCaption() {
  width: 100%;
  .container {
    background-color: #fff;
    padding-bottom: 20px;
    .bg-text {
      text-align: center;
      position: relative;
      .text {
        display: inline-block;
        font-size: 100px;
        font-weight: 700;
        line-height: 120px;
        letter-spacing: 5.55555534362793px;
        color: #fff;
        text-shadow: -1px 1px 0 #606875, 1px 1px 0 #606875, 1px -1px 0 #606875,
          -1px -1px 0 #606875;
        padding: 20px 0;
        opacity: 15%;
      }
      .title {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 35px;
        font-weight: 700;
        line-height: 42px;
        color: #001d48;
        text-transform: uppercase;
        span {
          color: #0046ac;
          display: inline-block;
          padding-left: 13px;
        }
      }
    }
  }

  @media (max-width: 992px) {
    background-color: #fff;
    .container {
      .bg-text {
        .text {
          font-size: 70px;
          line-height: 100px;
          letter-spacing: 5px;
        }
        .title {
          font-size: 25px;
          line-height: 35px;
          span {
            padding-left: 10px;
          }
        }
      }
    }
  }

  @media (max-width: 575px) {
    .container {
      .bg-text {
        .text {
          font-size: 45px;
          line-height: 45px;
          letter-spacing: 2px;
          padding: 16px 0;
        }
        .title {
          font-size: 16px;
          line-height: 19px;
        }
      }
    }
  }
  @media (max-width: 350px) {
    .container {
      .bg-text {
        .text {
          font-size: 35px;
        }
      }
    }
  }
}

@mixin loadingStye() {
  background: #eee;
  background: linear-gradient(110deg, #ececec 8%, #f5f5f5 18%, #ececec 33%);
  border-radius: 5px;
  background-size: 200% 100%;
  animation: 1.5s shine linear infinite;
}
@keyframes shine {
  to {
    background-position-x: -200%;
  }
}
