.additional-page {
  width: 100%;
  min-height: 70vh;
  .container {
    .content {
      margin: 15px 0;
      color: #334155;
      p{
        margin-bottom: 16px;
      }
    }

    .caption {
      font-size: 24px;
      font-weight: 600;
      line-height: 32px;
      margin: 20px 0;
      color: #0f172a;
    }
    .text {
      font-size: 16px;
      font-weight: 500;
      line-height: 24px;
      margin-bottom: 10px;
    }
  }
}
