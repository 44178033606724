@import "../mixins";
/* ---------------------------------- Card ---------------------------------- */
.general-card {
  min-height: 348px;
  border-radius: 15px;
  .top {
    background-color: #fff;
    padding: 10px 15px;
    font-weight: 500;
    color: #001d48;
    border-top-right-radius: 15px;
    border-top-left-radius: 15px;
    min-height: 65px;

    .title {
      font-size: 16px;
      line-height: 22px;
      margin-bottom: 3px;
      color: inherit;

      overflow: hidden;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      text-overflow: ellipsis;
      -webkit-line-clamp: 2;
    }
  }
  .middle {
    width: 100%;
    width: 100%;
    aspect-ratio: 1 / 1;
    background-color: #fff;
    // height: 136px;
    .placeholder {
      display: inline-block;
      width: 100%;
      height: 100%;
      @include loadingStye();
    }
    img {
      width: 100%;
      height: 100%;
      // object-fit: cover;
    }
  }
  .bottom {
    background-color: #fff;
    padding: 10px 15px;
    min-height: 150px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border-bottom-right-radius: 15px;
    border-bottom-left-radius: 15px;
    .info {
      .title {
        font-size: 14px;
        font-weight: 700;
        line-height: 20px;
        color: #001d48;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      .date {
        margin-bottom: 5px;
        font-size: 11px;
        font-weight: 400;
        line-height: 14px;
        color: #00275f;
      }
    }
    .company-name {
      font-size: 14px;
      font-weight: 500;
      line-height: 20px;
      color: #00275f;
    }
  }
}
@media (max-width: 575px) {
  .general-card {
    min-height: 244px;
    border-radius: 15px;
    .top {
      padding: 7px 10px;
      .title {
        font-size: 12px;
        line-height: 14px;
      }
      .subtitle {
        font-size: 10px;
        line-height: 15px;
      }
    }
    // .middle {
    //   height: 95px;
    // }
    .bottom {
      padding: 7px 10px;
      min-height: 105px;
      .info {
        .title {
          font-size: 12px;
          line-height: 18px;
        }
        .date{
          font-size: 10px;
        }
      }
      .company-name {
        font-size: 12px;
        line-height: 14px;
      }
    }
  }
}
