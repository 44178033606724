@import "../../mixins";
.general-info-detail {
  @include specialCaption();
}

.general-info-detail {
  .container {
    .info {
      width: 82.6%;
      margin: 0 auto;
      border-radius: 70px;
      box-shadow: 0px 11px 55px 0px #3333331f;
      padding: 40px;
      font-size: 14px;
      font-weight: 400;
      line-height: 18px;
      pre {
        width: 100%;
        page-break-inside: avoid;
        white-space: pre-wrap;
        white-space: -moz-pre-wrap;
        white-space: -pre-wrap;
        white-space: -o-pre-wrap;
        font-size: 14px;
        font-weight: 400;
        line-height: 18px;
      }
      .title {
        font-size: 14px;
        font-weight: bold;
      }
    }
  }
}
@media (max-width: 992px) {
  .general-info-detail {
    .container {
      padding-bottom: 15px;
      .info {
        width: 100%;
        padding: 40px 30px;
      }
    }
  }
}

@media (max-width: 575px) {
  /* ----------------------------- Training-Text ---------------------------- */
  .general-info-detail {
    .container {
      .info {
        margin-top: 15px;
        width: 100%;
        padding: 50px 20px;
      }
    }
  }
}
@media print {
  .general-info-detail {
    display: none;
  }
}
