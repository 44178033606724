.all-ticket {
  width: 100%;
  border-radius: 5px;
  background-color: #fff;
  padding: 20px;
  min-height: 70vh;
  .type-tab {
    display: flex;
    gap: 10px;
    margin-bottom: 20px;
    .type-tab-item {
      padding: 5px 10px;
      border-bottom: 2px solid transparent;
      cursor: pointer;
      transition: 0.3s ease;
      user-select: none;
      &.active {
        border-bottom-color: #0046ac;
      }
    }
  }
  .ticket-list {
    .alert {
      width: 100%;
      height: 200px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 14px;
      font-weight: 500;
      line-height: 16.67px;
      color: #7f8ea3;
    }
    .ticket-item {
      padding: 6px 10px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 15px;
      border-bottom: 1px solid #e6edf7;
      &:last-child {
        border-bottom-color: transparent;
      }
      .info-area {
        display: flex;
        align-items: center;
        gap: 15px;
        cursor: pointer;
        .icon {
          img {
            width: 30px;
            height: 30px;
          }
        }
        .action-name {
          font-size: 16px;
          font-weight: 500;
          line-height: 16.67px;
          color: #001d48;
        }
      }
      .watch-ticket {
        font-size: 12px;
        font-weight: 400;
        line-height: 16px;
        text-align: left;
        color: #ffffff;
        background: #0046ac;
        padding: 5px 10px;
        border-radius: 13px;
        border: none;
        outline: none;
        cursor: pointer;
        display: flex;
        align-items: center;
        gap: 5px;
        text-wrap: nowrap;
      }
    }
  }
}

@media (max-width: 992px) {
  .all-ticket {
    padding: 0px;
    min-height: 50vh;
  }
}
@media (max-width: 575px) {
  .all-ticket {
    .type-tab {
      .type-tab-item {
        font-size: 14px;
      }
    }
    .ticket-list {
      min-height: 24vh;
      .ticket-item {
        padding: 6px 0px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-start;

        .info-area {
          .icon {
            img {
              width: 25px;
              height: 25px;
            }
          }
          .action-name {
            font-size: 12px;
          }
        }
        .watch-ticket {
          width: 100%;
          font-size: 12px;

          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
    }
  }
}

@media print {
  .all-ticket {
    display: none;
  }
}
