.profile-navigate {
  width: 215px;
  position: absolute;
  top: 0;
  left: 0;

  .profil-tab-list {
    border-radius: 5px;
    background-color: #fff;
    overflow: hidden;
    &:last-child {
      margin-top: 5px;
      .profil-tab-item {
        .text {
          color: #ff3d71;
        }
      }
    }
    .profil-tab-item {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 10px 8px 10px 15px;
      cursor: pointer;
      transition: 0.3s ease;
      border-left: 5px solid transparent;
      .text {
        font-size: 14px;
        font-weight: 500;
        line-height: 14px;
        color: #001d48;
      }
      .icon {
        display: flex;
        align-items: center;
        width: 16px;
        height: 16px;
        opacity: 0;
        visibility: hidden;
        transition: .3s ease;
        img {
          width: 100%;
          height: 100%;
        }
      }
      &:hover {
        background-color: #cccccc3b;
      }
      &.active {
       
        border-left-color: #0046ac;
        .text {
          font-weight: 700;
        }
        .icon {
            opacity: 1;
            visibility: visible;
      
          }
      }
    }
  }
}
@media print {
  .profile-navigate {
    display: none;
  }
}