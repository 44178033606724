/* ------------------------------ Google Family ----------------------------- */
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap");

/* ------------------------------- Reset Code ------------------------------- */

:root {
  --red: #ff3d71;
  --green: #00d68f;
  --white: #fff;
  --black: #000;
  --grey-norm: #c3c3c3;
  --grey-light: #f0f2f4;
  --blue-dark: #001d48;
  --blue-norm: #00275f;
  --blue-light: #0046ac;
  --Cyan: #009cac;
}
* {
  margin: 0;
  padding: 0;
  text-decoration: none;
  list-style: none;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
}

.container {
  width: 72.22%;
  min-width: 1000px;
  margin: 0 auto;
  padding: 0 20px;
}
.row {
  width: 100%;
  display: flex;
}
body {
  background-color: #f0f2f4;
}
h1,
h2 {
  font-weight: 700;
}
h3 {
  font-weight: 500;
}
h4 {
  font-weight: 600;
}
.swiper-button-next,
.swiper-button-prev {
  &::after {
    font-size: 18px;
    padding: 10px 14px;
    color: #fff;
    border-radius: 100%;
    background-color: rgba(0, 0, 0, 0.3);
    transition: 0.33s ease;
    opacity: 0;
  }
}

.scroll {
  overflow-y: scroll;
  &::-webkit-scrollbar-track {
    background-color: transparent;
  }
  &::-webkit-scrollbar {
    width: 3px;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background-color: #757575;
  }
  &::-webkit-scrollbar-track-piece:end {
    margin-bottom: 15px;
  }

  &::-webkit-scrollbar-track-piece:start {
    margin-top: 15px;
  }
}

@media (max-width: 992px) {
  .container {
    width: 83.45%;
    min-width: auto;
  }
  .swiper-button-next,
  .swiper-button-prev {
    &::after {
      font-size: 18px;
      padding: 10px 14px;
      color: #fff;
      border-radius: 100%;
      background-color: rgba(0, 0, 0, 0.3);
      transition: 0.33s ease;
      opacity: 1;
    }
  }
}
@media (max-width: 575px) {
  .container {
    width: 100%;
    min-width: auto;
  }
}
