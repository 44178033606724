.conferance-course-choose-packet {
  .container {
    .caption {
      @include caption();
    }
    .select {
      background-color: #fff;
      padding: 14px 20px;
      border-radius: 10px;
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 10px;
      cursor: pointer;
      .text {
        font-size: 16px;
        font-weight: 600;
        line-height: 22px;
        color: #001d48;
      }
      .icon {
        display: flex;
        align-items: center;
        img {
          width: 20px;
          height: 20px;
          object-fit: cover;
        }
      }
      .pay-info-alert {
        padding: 4px 18px;
        border-radius: 21px;
        border: none;
        outline: none;
        cursor: pointer;
        font-size: 14px;
        font-weight: 500;
        line-height: 24px;
        color: #ffffff;
        text-wrap: nowrap;
        background: #0046ac;
      }
    }
    .select-box {
      width: 100%;
      height: 0;
      opacity: 0;
      visibility: hidden;
      transition: 0.3s ease;
      height: 0;
      &.avtiveSelectBox {
        margin: 9px 0;
        opacity: 1;
        visibility: visible;
      }
      .select-list {
        width: 100%;
        border: 1px solid #0046ac;
        background-color: #fff;
        border-radius: 10px;
        overflow: hidden;
        .select-item {
          padding: 14px 20px;
          width: 100%;
          display: flex;
          justify-content: space-between;
          align-items: center;
          gap: 15px;
          cursor: pointer;
          transition: 0.5s ease;

          &:hover {
            background-color: #eeeeee;
          }
          .price-info-area {
            display: flex;
            gap: 5px;
            align-items: center;
            .text {
              font-size: 16px;
              font-weight: 500;
              line-height: 22px;
              color: #001d48;
              overflow-wrap: anywhere;
            }
            .money {
              font-size: 14px;
              font-weight: 700;
              line-height: 24px;
              background-color: #5483c7;
              color: #fff;
              padding: 0 10px;
              border-radius: 15px;
              text-wrap: nowrap;
            }
          }

          .btn-gorup {
            display: flex;
            gap: 9px;
            align-items: center;
            .reserve,
            .pay {
              padding: 4px 18px;
              border-radius: 21px;
              border: none;
              outline: none;
              cursor: pointer;
              font-size: 14px;
              font-weight: 500;
              line-height: 24px;
              color: #ffffff;
              text-wrap: nowrap;
            }
            .reserve {
              background: #00d68f;
              &.cancel {
                background: #ff3d71;
              }
            }
            .pay {
              background: #0046ac;
              &.disabled {
                display: none;
              }
            }
          }
        }
      }
    }
    .alert {
      margin: 15px 0;
      font-size: 14px;
      font-weight: 400;
      line-height: 18px;
      color: #001d48;
      a{
        color: #0046ac;
      }
    }
  }
}

/* ------------------------------- Responsive ------------------------------- */
@media (max-width: 992px) {
  .conferance-course-choose-packet {
    .container {
      .caption {
        padding: 16px 0;
        font-size: 18px;
        line-height: 18px;
      }
      .select {
        padding: 12px 17px;
        .text {
          font-size: 14px;
          line-height: 22px;
        }
      }
      .select-box {
        .select-list {
          .select-item {
            padding: 13px 20px;
            flex-direction: column;
            border-bottom:1px solid #ccc ;
            .price-info-area {
              align-self: flex-start;
              width: 100%;
              justify-content: space-between;
              .text {
                font-size: 14px;
                line-height: 20px;
              }
              .price {
                font-size: 14px;
              }
            }
            .btn-gorup{
              width: 100%;
              button{
                width: 100%;
                text-align: center;
              }
            }
          }
        }
      }
    }
  }
}

@media (max-width: 575px) {
  .conferance-course-choose-packet {
    .container {
      .caption {
        padding: 12px 0;
        font-size: 16px;
        line-height: 20px;
      }
      .select {
        padding: 12px 10px;
        .text {
          font-size: 12px;
          line-height: 16px;
        }
      }
      .select-box {
        .select-list {
          .select-item {
            padding: 15px 10px;
            .price-info-area{

              .text {
                font-size: 12px;
                line-height: 16px;
              }
              .price {
                padding: 6px 4px;
                font-size: 12px;
                line-height: 16px;
              }
            }
          }
        }
      }
    }
  }
}
@media print {
  .conferance-course-choose-packet {
    display: none;
  }
}
