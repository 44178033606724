.loader {
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  gap: 30px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #e6edef;
  z-index: 999999999;
  .loading03 {
    display: flex;
    span {
      margin: 0 10px;
      font-size: 20px;
      font-weight: 600;

      animation: loading03 0.7s infinite alternate;
      @for $i from 1 through 14 {
        &:nth-child(#{$i + 1}) {
          animation-delay: #{$i * 0.1}s;
        }
      }
    }
  }
}

@keyframes loading03 {
  0% {
    transform: scale(1.5);
  }
  100% {
    transform: scale(0.8);
  }
}
@media (max-width: 768px) {
  .loader {
    .loading03 {
      span {
        margin: 0 6px;
        font-size: 18px;
        font-weight: 600;
      }
    }
  }
}
@media (max-width: 400px) {
  .loader {
    .loading03 {
      span {
        margin: 0 6px;
        font-size: 15px;
        font-weight: 600;
      }
    }
  }
}
@media (max-width: 350px) {
  .loader {
    .loading03 {
      span {
        margin: 0 6px;
        font-size: 12px;
        font-weight: 600;

        animation: loading03 0.7s infinite alternate;
        @for $i from 1 through 15 {
          &:nth-child(#{$i + 1}) {
            animation-delay: #{$i * 0.1}s;
          }
        }
      }
    }
  }
}
